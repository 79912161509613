
.sin-category{
	padding: 0 15px;
	.cat-name{
		text-align: center;
		margin-top: -75px;
		a{
			color: $forth_color;
			font-size:22px;
			@include WorkSans-bold;
			text-transform: uppercase;
			padding:17px 32px;
			background:rgba(255, 255, 255, 0.96);
			border:1px solid #abacac;
			margin: 0 auto;
			h5{
				margin-bottom: 0px;
				line-height: 29px;
				font-size:22px;
				text-align: center;
				@include WorkSans-bold;
				span{
					font-weight: 400;
				}
			}
			&:hover{
				color:$main_color;
			}
		}
	}
}