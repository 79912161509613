/*!
  Theme Name: Comercio
  Theme URI:
  Author:
  Author
  Description: Theme Description
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain:
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Mobile Menu
    ## Dropdown menu
    ## Mega menu area
    ## Header 2
    ## Navbar Three
    ## Slider
    ## Slider Style two
    ## Slider Style three
    ## Product Banner
    ## Product Filter
    ## Product Filter Style 2
    ## Product three
    ## Features
    ## Banner and  Product area
    ## Banner and  Product area 2
    ## Countdown
    ## Small Product
    ## category
    ## logo_carousel
    ## testimonial
    ## breadcrumb
    ## Shop Page
    ## Product Page
    ## Cart Page
    ## Blog Page
    ## Blog sidebar
    ## Contact page
    ## Quickview
    ## Starting popup news letter
    ## Subscribe
    ## Instagram
    ## Footer
    ## Footer style 2
    ## Backtotop
    ## Responsive




  --------------------------------------------------------------*/

@import "base/variables";
@import "base/mixins";
@import "base/base";
@import "elements/elements";

