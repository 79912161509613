.pb-info{
		position: absolute;
		bottom: 80px;
		left: 160px;
		text-align: center;
		background: #4f4f50;
		opacity: 0;
		padding: 36px 14px;
		transform: translateX(-50%);
		width: 300px;
		height: 140px;
		@include transition(all 0.2s ease-in-out);
	&:after{
		position: absolute;
		content: '';
		width: 320px;
		height: 160px;
		left: -10px;
		top: -10px;
		z-index: -2;
		border: 5px solid #4f4f50;
		opacity:.9;
	}
	p{
		font-size:20px;
		@include WorkSans-bold;
		color: #fff;
		text-transform: uppercase;
	}
	h6{
		font-size:30px;
		@include WorkSans-bold;
		color: $main_color;
	}
}
	.prod-banner-two{
		position: relative;
		margin-top: 30px;
	}
	.prod-banner-two:hover .pb-info{
		left:50%;
		opacity:.9;
	}
	.sin-prod-car {
	padding: 0 15px;
	}

