
.instagram-area{
	width: 100%;
	overflow: hidden;
}
.sin-instagram{
	position: relative;
	overflow:hidden;
	&:after{
		content: '';
		position: absolute;
		top:0;
		left: 0;
		width:100%;
		height:100%;
		background:#1e1e1e;
		visibility:hidden;
		opacity: 0;
		z-index: 2;
		@include transition(all 0.3s ease-in-out);
	}
	.hover-text{
		text-align:center;
		position:absolute;
		width:100%;
		left:0;
		top:50%;
		transform: translateY(-50%);
		visibility: hidden;
		opacity:0;
		z-index:99999;
		@include transition(all 0.3s ease-in-out);
		img{
			width:45px;
			height:45px;
			margin: 0 auto;
		}
		span{
			color: #fff;
			font-size:22px;
			@include WorkSans-bold;
			display: block;
			text-transform:uppercase;
		}
	}
}
.sin-instagram:hover .hover-text{
	visibility: visible;
	opacity:1;
	@include transition(all 0.1s ease-in-out);
}
.sin-instagram:hover:after{
	visibility: visible;
	opacity:.6;
	@include transition(all 0.2s ease-in-out);
}
.instagram-area-two .sin-instagram {
	&:after{
		content: '';
		position: absolute;
		top:0;
		left: 0;
		width:100%;
		height:100%;
		background:#ebebeb;
		visibility:hidden;
		opacity: 0;
		z-index: 2;
		@include transition(all 0.3s ease-in-out);
	}
	.hover-text{
	span{
		color: $second_color;
		font-size:22px;
		@include WorkSans-semi-bold;
		border: 2px solid #252525;
		padding: 10px 27px;
		}
	}

}
.instagram-area-two .sin-instagram:hover:after{
	visibility: visible;
	opacity:.6;
	@include transition(all 0.2s ease-in-out);
}
.instagram-area-two{
	width: 100%;
	overflow: hidden;
}



