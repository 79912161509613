


.mask-overlay {
	content: '';
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}
.close-qv {
	position: absolute;
	right: 18px;
	cursor:pointer;
	z-index:99999;
}
.quickview-wrapper.open {
	visibility: visible;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 1;

}
.quickview-wrapper {
	position: fixed;
	width: 1000px;
	height: 630px;
	border-radius: 5px;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -60%);
	-ms-transform: translate(-50%, -60%);
	transform: translate(-50%, -60%);
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: inline-block;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background: #fff;
	padding: 15px;
}

.close-menu {
	float: right;
	margin-top: 13px;
	cursor: pointer;
}

.slick-slide img {
	display: block;
	width: 100%;
}
.slick-slide > div {
	text-align: center;
	margin: 0 7px;
}

.slick-prev::before, .slick-next::before {
	color:#000;
	font-family: 'slick';
	font-size: 20px;
	line-height: 1;
	opacity: .75;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.slick-next::before {
	content: '→';
}
.slick-prev::before {
	content: '←';
}
.slick-prev, .slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	display: block;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	padding: 0;
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;

}

.slick-prev::before, .slick-next::before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-size: 20px;
	color: #908b8b;
}

.slick-next {
	right: 15px;
	padding: 8px;
	display: block;
	width: 24px;
	height: 37px;
	top: 50%;
	z-index: 999;
	margin-top: -18px;
	padding-left: 0px;
	padding-right: 0px;
}
.slick-prev {
	left: 15px;
	padding: 8px;
	display: block;
	width: 24px;
	height: 37px;
	top: 50%;
	z-index: 999;
	margin-top: -18px;
	padding-left: 0px;
	padding-right: 0px;
}

.slick-next::before {
	content: '\f105';
}
.slick-prev::before {
	content: '\f104';
}

/*--------------------------------------------------------------
  ##  Starting popup news letter
  --------------------------------------------------------------*/
.popup-banner{
	width:1050px;
	height:550px;
	background-image:url(../../media/images/banner/mod1.jpg);
	background-size:cover;
	text-align:center;
	padding-top:50px;
	h3{
		font-size:30px;
		color:$second_color;
		@include WorkSans-bold;
		position:relative;
		padding-bottom: 15px;
		span{
			color:$main_color;
		}

	}
	p{
		font-size: 16px;
		padding: 0 100px;
		color:$fifth_color;
	}

}

.popup-banner.style-two{
	padding-top:150px;
	h3{
		&:after{
			content: '';
			height: 4px;
			width: 54px;
			background: #3a3a3a;
			bottom: 0;
			left: 50%;
			z-index: 99999;
			position: absolute;
			border-radius: 2px;
			margin-left: -56px;
		}
		&:before{
			content: '';
			height: 4px;
			width: 24px;
			background: #d19e66;
			bottom: 0;
			left: 50%;
			z-index: 99999;
			position: absolute;
			border-radius: 2px;
			margin-left: 8px;
		}
	}
}


.modal-dialog {
	max-width: 1050px;
	margin: 1.75rem auto;
	max-height: 550px;
}
.popup-subscribe{
	margin: 0 auto;
	margin-top: 0px;
	margin-bottom: 0px;
	float: none;
	margin-top: 15px;
	margin-bottom: 15px;
	.subscribe-wrapper{
		margin: 20px auto 20px;
		float: none;
	}
}
.popup-banner .close {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #a4a4a4;
	text-shadow: none;
	opacity: 1;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	border: 3px solid #fff !important;
	background: #343b41;
	position: absolute;
	right: -20px;
	top: -20px;
	z-index:9999;
	cursor:pointer;
}
.modal-dialog {
	pointer-events: inherit;
}
.popup-banner .subscribe-wrapper button {
	border: none;
	border-radius: 0px;
	height:50px;
}
.popup-banner .subscribe-wrapper input {
	border: none;
	border-radius: 0;
	height: 50px;
}

.popup-banner.style-two {
	background-image: url(../../media/images/banner/mod2.jpg);
	padding-right: 33%;
}
.popup-banner.style-two .subscribe-wrapper input {
	width: 100%;
	background: #f5f5f5;
	margin-bottom: 19px;
	height:55px;
}
.popup-banner.style-two  .subscribe-wrapper button {
	background: #d19e66;
	color: #fff;
	height: 55px;
	width: 250px;
}
.popup-banner.style-two .popup-subscribe .subscribe-wrapper {
	margin: 20px auto;
	float: none;
	width: 450px;
}

/* Color Checkbox */
.color-checkboxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 35px 0;
}

.color-checkboxes h4 {
  margin-right: 10px;
	font-size: 16px;
	font-weight: 400;
	color: #636363;
}

.color-checkboxes #col-Blue-label {
  background: #2196f3;
}

.color-checkboxes #col-Green-label {
  background: #8bc34a;
}

.color-checkboxes #col-Yellow-label {
  background: #fdd835;
}

.color-checkboxes #col-Orange-label {
  background: #ff9800;
}

.color-checkboxes #col-Red-label {
  background: #f44336;
}

.color-checkboxes #col-Black-label {
  background: #222222;
}

.color-checkbox {
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  border-radius: 16px;
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  cursor: pointer;
}




.color-checkbox::after {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 5px;
	left: 5px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0;
	-webkit-transition: opacity 0.1s;
	-o-transition: opacity 0.1s;
	transition: opacity 0.1s;
	text-align: center;
	background: #fff;
	border-radius: 69px;
}


.color-checkbox__input:checked + .color-checkbox:after {
  opacity: 1;
}

.color-checkbox__input {
  visibility: hidden;
  width: 0;
  pointer-events: none;
  position: absolute;
}

.quickview .add-tocart-wrap a.add-to-cart {
	width: 210px;
}
