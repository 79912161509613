$font_primary: 'OpenSans', sans-serif;
$font_heading: 'Montserrat', sans-serif;

$main_color: #d19e66;
$second_color: #3f3f3f;
$third_color: #434343;
$forth_color: #233e47;
$fifth_color: #636363;
$title_color: #525353;
$bg_color_one:#f5f5f5;
$six_color:#2e2e2e;
$seven_color:#7b7b7b;
$eight_color:#464646;