.small-sec-title {
	text-align: left;
	padding-bottom: 30px;
	h6 {
		font-size: 22px;
		@include WorkSans-bold;
		color: $main_color;
		text-transform:uppercase;
		padding-bottom: 50px;
		position:relative;

		span{
			color : $second_color;
		}
		&:after{
			content: '';
			position: absolute;
			left: 0;
			width: 108px;
			height: 30px;
			background-image: url(../../media/images/icon/line2.png);
			background-repeat: no-repeat;
			background-position: center;
			top: 35px;
			margin-left: 0;
		}
	}

}

.product-small {
	padding: 110px 0 90px;
}

.sin-product-s {
	overflow: hidden;
	margin-bottom: 30px;
}

.sin-product-s{
	.sp-img{
		float: left;
		width: 33%;
	}
}

.small-pro-details {
	width: 66%;
	float: right;
	padding-left: 11px;
	padding-top: 3px;
	overflow:hidden;
	.title {
		font-size: 18px;
		@include WorkSans-semi-bold;
		margin: 0;
		a {
		color: $second_color;
		font-weight:400;
		&:hover{
			color:$main_color;
		}
		}
	}
	span{
		font-size:16px;
		color: $main_color;
		@include WorkSans-normal;
		display:block;
	}
	> a{
		font-size: 14px;
		color: #565454;
		text-transform: uppercase;
		font-family: 'Work Sans', sans-serif;
		font-weight: 400;
	}
	.rating {
		a {
			color: #a4a3a1;
		}
	}
}