@media (min-width: 576px) {


.cart-table{
	.tables{
		thead{
		tr{

			th{
				@include WorkSans-bold;
				color:#393939;
				font-size: 16px;
				border-bottom:2px solid #545353;
				border-top: none;
				text-transform:uppercase;
				text-align:center;
			}
		}

		}
	tbody{
		tr{
			td{
				display:table-cell;
				vertical-align:middle;
				color :$title_color;
				border-top: none;
				border-bottom:1px solid #d8d7d7;
				text-align: center;
				padding: 30px 0px;
				a{
					color :$title_color;
				}
				.product-image{
					border:5px solid #fff;
					@include box-shadow(0px, 0px ,8px, 0px, rgba(109, 109, 109, 0.11));
				}
				.quantity{
					input{
						width: 74px;
						background: #f3f3f3;
						border-radius: 20px;
						padding: 3px 18px;
						height: 39px;
						border: none;
					}
				}
				.product-title a{
					font-size: 18px;
					@include WorkSans-medium;


				}
			}
		}

	}
	}

}

.tables {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}


}

.cart-table .tables tbody tr td .product-title a:hover {
	color: $main_color;
}

.cart-area{
	padding:50px 0 50px;
}
.cart-btn-left{
	a{
		margin-right: 10px;
	}

}
.cart-btn-section{
	padding: 5px 0px 30px;
	a{
		@include WorkSans-semi-bold;
		color:#fff;
		background:$main_color;
		height:50px;
		width:190px;
		font-size:18px;
		text-transform:uppercase;
		text-align: center;
		line-height: 50px;

	}
	.coupon-code{
		background:#f6f6f6;
		border:1px solid #e6e6e6;
		color:#8a8a8a;
	}

}

.cart-btn-right a{
	@include WorkSans-semi-bold;
		color:#fff;
		background:$second_color;
		height:50px;
		width:190px;
		font-size:18px;
		text-transform:uppercase;
		text-align: center;
		line-height: 50px;
		float:right;
}
.note
{
	padding-top: 45px;
	textarea{
		margin-top: 5px;
		margin-right: 7px;
		width: 100%;
		height: 130px;
		padding:15px;
	}
	span{
		@include Roboto-bold;
		color:#3b3b3b;
		width: 100%;
		display: block;
		margin-bottom: 10px;
	}

}

.cart-subtotal{
	background:#f6f6f6;
	padding:50px 30px;
	text-align:center;
	p{
		position: relative;
		font-size:18px;
		@include WorkSans-semi-bold;
		color:#2e2e2e;
		text-align:center;
		margin-bottom: 25px;
		&:after{
			position:absolute;
			content:'';
			left:50%;
			margin-left:-15px;
			height:2px;
			width:30px;
			background:$main_color;
			bottom: -8px;

		}
	}
	ul{
		li{
			width:100%;
			text-align:right;
			font-size:16px;
			padding: 19px 0 7px;
			@include WorkSans-medium;
			color:#525353;
			border-bottom: 1px solid #c2c2c2;
			span{
				float:left;
			}
		}
	}
	a{
		@include WorkSans-semi-bold;
		color:#fff;
		background:$main_color;
		height:50px;
		font-size:18px;
		text-transform:uppercase;
		text-align: center;
		line-height: 50px;
		padding:0 20px;
		margin-top: 40px;
	}
}

.cart-subtotal a:hover {
	background: #3f3f3f;

}

.cart-btn-section a:hover {
	background: #3f3f3f;
	color: #fff;
}

.cart-btn-right a:hover{
	background: $main_color;
}






.account-details{
	padding:50px 30px;
	text-align:center;
	p{
		position: relative;
		font-size:18px;
		@include WorkSans-semi-bold;
		color:#2e2e2e;
		text-align:center;
		margin-bottom: 22px;
	}
	ul{
		li{
			width:100%;
			text-align:center;
			font-size:16px;
			padding: 2px;
			@include WorkSans-medium;
			color:#525353;
			span{
				float:left;
			}
		}
	}
	a{
		@include WorkSans-semi-bold;
		color:#fff;
		background:$main_color;
		height:50px;
		font-size:18px;
		text-transform:uppercase;
		text-align: center;
		line-height: 50px;
		padding:0 20px;
		margin-top: 40px;
	}
}

.account-details a:hover {
	background: #3f3f3f;

}
.account-table {
	padding-top: 50px;
	text-align: center;

	a{
		color:#3f3f3f;
	}
}

.account-table h6{
	font-size: 18px;
color: #2e2e2e;
font-weight: 600;
padding-bottom: 30px;
}

.account-area {
	padding: 70px 0px;
}

.account-table th {
	text-align: inherit;
	padding: 10px 0;
	border: 1px solid #ddd;
	text-align: center;
}
.account-table td {
	border: 1px solid #ddd;
	text-align: center;
	padding: 10px 0;
}

.cart-table tr th {
	padding-bottom: 15px;
}
