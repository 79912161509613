/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Header 2
  --------------------------------------------------------------*/
@import "navbar-2";

/*--------------------------------------------------------------
  ##  Slider
  --------------------------------------------------------------*/
@import "slider";

/*--------------------------------------------------------------
  ##  Product Banner
  --------------------------------------------------------------*/
@import "pro-banner";

/*--------------------------------------------------------------
  ##  Product Filter
  --------------------------------------------------------------*/
@import "pro-filter";

/*--------------------------------------------------------------
  ##  Product Filter Style Two
  --------------------------------------------------------------*/
@import "pro-filter-two";

/*--------------------------------------------------------------
  ##  Features
  --------------------------------------------------------------*/
@import "feature";

/*--------------------------------------------------------------
  ##  Banner and  Product area
  --------------------------------------------------------------*/
@import "product-style-two";

/*--------------------------------------------------------------
  ##  Banner and  Product area 2
  --------------------------------------------------------------*/
@import "pro-banner-two";

/*--------------------------------------------------------------
  ##  Small Product
  --------------------------------------------------------------*/
@import "small-product";

/*--------------------------------------------------------------
  ##  Caegory
  --------------------------------------------------------------*/
@import "category";

/*--------------------------------------------------------------
  ##  Logo Carousel
  --------------------------------------------------------------*/
@import "logo_carousel";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
@import "testimonial";


/*--------------------------------------------------------------
  ##  Breadcrumb
  --------------------------------------------------------------*/
@import "breadcrumb";


/*--------------------------------------------------------------
  ##  Shop Page
  --------------------------------------------------------------*/
@import "shop";


/*--------------------------------------------------------------
  ##  Cart page
  --------------------------------------------------------------*/
@import "cart";

/*--------------------------------------------------------------
  ##  Blog Page
  --------------------------------------------------------------*/
@import "blog";

/*--------------------------------------------------------------
  ##  Contact page
  --------------------------------------------------------------*/
@import "contact";

/*--------------------------------------------------------------
  ##  Quickview
  --------------------------------------------------------------*/
@import "quick-view";


/*--------------------------------------------------------------
  ##  Subscribe
  --------------------------------------------------------------*/
@import "subscribe";

/*--------------------------------------------------------------
  ##  Instagram
  --------------------------------------------------------------*/
@import "instagram";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";


/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@import "responsive";