.client-car.owl-carousel.owl-drag .owl-item {
	text-align: center;
}

.client-car.owl-theme .owl-nav [class*="owl-"] {
	color: #b7b7b7;
	font-size: 17px;
	padding: 2px 12px;
	background: #fff;
	border-radius: 0px;
	line-height: 23px;
}
.client-car.owl-carousel .owl-nav .owl-next{
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-20px);
}
.client-car.owl-carousel .owl-nav .owl-next:hover,
.client-car.owl-carousel .owl-nav .owl-prev:hover{
	color:#d19e66;
}
.client-car.owl-carousel .owl-nav .owl-prev {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-20px);
}
.client-car.owl-theme .owl-nav {
	margin-top: 0;
}
.logo-carousel {
	padding: 30px 0px;
}