/*--------------------------------------------------------------
  ##  Fonts
  --------------------------------------------------------------*/

@import "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700";
@import "https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700";





body {
	font-family: 'Work Sans', sans-serif;
	color: #3f3f3f;
}

img{
	max-width: 100%;
}

svg {
	display: block;
}

a {
	transition: all 0.15s ease-in-out;
	display:inline-block;
	 outline: 0;
}

a,
a:hover {
	text-decoration: none;
}

ul {
	margin: 0;
	list-style: none;
	padding: 0;
}
.custom-container{
	max-width: 1680px
}
h1, h2, h3, h4, h5, h6{

}
p{
	margin-bottom: 10px;
}
.padding-50 {
	padding: 50px 0;
}

.padding-120{
	padding:120px 0!important;
}
.container-two {
	max-width: 1430px;
}
.padding-150{
	padding:150px 0;
}
.bg-one{
	background: $bg_color_one
}
.pb-60{
	padding-bottom: 60px!important;
}
.pb-50{
	padding-bottom:50px;
}
.pad-45{
	padding:45px 0!important;
}
.pb-30{
	padding-bottom:30px;
}
.no-padding{
	padding:0px;
}
.bg-two {
	background-color: #f0f0f0;
}
.padding-top-120{
	padding-top: 120px;
}
.padding-top-90{
	padding-top: 90px;
}
.mt-0{
	margin-top: 0;
}