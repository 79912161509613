
.shop-area {
	padding: 45px 0px 120px;
}
.shop-area.style-two {
	padding-bottom: 100px;
}

.shop-sorting-area.row {
	margin-bottom: 60px;
}

.sidebar-search button {
	width: 50px;
	height: 45px;
	margin-left: -5px;
	color: #2e2e2e;
	font-size: 20px;
	border-left: none;
	cursor: pointer;
	background: transparent;
	position: absolute;
	right: 30px;
	border: none;
}

.sidebar-search input {
	width: calc(100%);
	border: 1px solid #e9e9e9;
	height: 45px;
	position: relative;
	float: left;
	padding-left: 20px;
	background: transparent;
	color: #222;
}

.sidebar-search {
	width: 100%;
}
.sidebar-widget{
	padding-bottom:70px;
	overflow: hidden;
	h6{
		color:$six_color;
		font-size:16px;
		@include WorkSans-semi-bold;
		margin-bottom:25px;
	}
}

.category-widget{
	ul{
		li{
			padding-left: 16px;
			padding-bottom: 5px;
			position: relative;
			&:after{
				content: '';
				width: 5px;
				height: 5px;
				background: #d19e66;
				position: absolute;
				left: 0;
				bottom: 14px;
				margin-top: -2px;
			}
			a{
				color:$seven_color;
				font-size:16px;
				@include WorkSans-normal;

			}
			span{
				color:$seven_color;
				font-size:16px;
				@include WorkSans-normal;
				float:right;

			}
		}
	}
}
.category-widget ul li:not(:first-child){

	padding-top: 13px;

}

#slider-range {margin-bottom: 25px;}
.ui-widget-content {
	background: #fff none repeat scroll 0 0;
	border: 1px solid #ccc;
	border-radius: 5px;
}
.ui-slider-range {background: #d19e66  none repeat scroll 0 0;}
p.sub-title {
	color: #e48080;
	display: block;
	font-family: "Lato";
	font-size: 14px;
	font-weight: 700;
	padding: 10px 15px 10px 0;
	text-transform: capitalize;
}
.price-range > span {
	margin-right: 5px;
	text-transform: capitalize;
	color: #464646;
	@include WorkSans-semi-bold;
}
.price-range input[type="text"] {
	border: medium none;
	float: none;
	height: 30px;
	letter-spacing: 3px;
	text-align: center;
	width: 56%;
	word-spacing: 7px;
	@include WorkSans-normal;
	color: $main_color;
	line-height:23px;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-hover {
	border: 2px solid #b35f03;
	background: #b35f03;
	font-weight: normal;
	color: #121212;
	border-radius: 50px;
	height: 16px;
	width: 16px;
}

.ui-widget.ui-widget-content {
	border: 0px solid #c5c5c5;
	background: #f1f1f1;
	border-radius: 0px;
	height: 6px;
	margin-left: 12px;
	margin-right: 10px;
}
.color-widget{
	ul{
		li{
			width: 25px;
			height: 25px;
			display: inline-block;
			margin-right: 3px;
			border: 1px solid #ddd;

			&:nth-child(1){
				background:#cdcd16;
			}
			&:nth-child(2){
				background:#45ce09;
			}
			&:nth-child(3){
				background:#cd163d;
			}
			&:nth-child(4){
				background:#e51996;
			}
			&:nth-child(5){
				background:#2e2e2e;
			}
			a{
				display:block;
				width:100%;
				height:100%;
				border: 1px solid #fff;
			}

		}
	}
}


.wid-pro{
	overflow: hidden;
	padding-bottom: 30px;
	.sp-img{
		float: left;
	}
}
.wid-pro{
	.small-pro-details {
		float: left;
		padding-left: 20px;
		padding-top: 1px;
		width:65%;
		.title {
			font-size: 16px;
			@include WorkSans-medium;
			margin-bottom: 5px;
			a {
				color: $title_color;
				&:hover{
					color:$main_color;
				}
			}
		}
		span{
			font-size:16px;
			color: $title_color;
			@include WorkSans-semi-bold;
			display:block;
			float: left;
			margin-right: 9px;
		}
		.pre-price{
			text-decoration:line-through;
			font-weight:400;
			color:#939393;
			font-size:16px;
		}
		> a{
			font-size:14px;
			color: $second_color;
			text-decoration: underline;
			text-transform:uppercase;
			@include WorkSans-semi-bold;
		}
		.rating {
			padding: 0px;
			padding-top: 0px;
			display: block;
			width: 100%;
			overflow: hidden;
			padding-top: 5px;
			a {
				color: #d19e66;
			}
		}
	}
}

.banner-wid{
	position:relative;
	padding-bottom:0px;
	text-align:center;
	a{
		text-align: center;
		font-size: 16px;
		font-family: 'Work Sans', sans-serif;
		font-weight: 400;
		color: #5e5e5e;
		text-transform: uppercase;
		position: absolute;
		top: 86%;
		left: 50%;
		transform: translateX(-50%);
	}
}

.Bg {
	background-color: rgb(46, 46, 46);
	box-shadow: 0.707px -0.707px 8px 0px rgba(109, 109, 109, 0.11);
	position: absolute;
	left: 135px;
	top: 1694px;
	width: 390px;
	height: 519px;
	z-index: 82;
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	color: #495057;
	background-color: #fff;
	border-color: transparent;
}
.nav-tabs {
	border-bottom: 0px solid #dee2e6;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	border-color: transparent;
}
.shop-btn{
	li{
		a.nav-link{
			color: #2e2e2e;
			padding: 1px;
			border-radius: 0px;
			width: 35px;
			text-align: center;
			height: 50px;
			font-size: 18px;
			line-height: 39px;
			&:hover{
				color: $main_color;

			}
		}
		a.nav-link.active{
			color: $main_color;

		}
	}
}

.shop-btn .flaticon-list {
	font-size: 23px;
	line-height: 37px;
}

.list-pro-det {
	text-align: left;
	.color-variation {
		float: none;
		margin: 9px 0px 15px;
	}
	.rating {
		float: none;
		a{
			color: #d19e66;
			font-size: 17px;
		}
	}
	h5.pro-title {
		float: none;
	}
	span {
		float: none;
		font-size: 26px;
		margin-top: 8px;
		display: inline-block;
		margin-bottom: 4px;
		color: $title_color;
	}
	a.btn-two{
		background: $main_color;
		margin-left:8px;
		&:after{
			border-color: $main_color;
		}
		&:before{
			border-color: $main_color;
		}
	}
	p {
		font-size: 16px;
		line-height: 28px;
		color: #636363;
		margin-bottom: 23px;
	}
}
.sin-product.list-pro .pro-icon {
	top: 50%;
	width: 200px;
	text-align: center;
	margin: 0 auto;
	text-align: left;
	left: 50%;
	transform: translate(-50%,-50%);
}
.sin-product.list-pro .pro-icon ul li a {
	color: #585858;
	font-size: 23px;
}
.sin-product.list-pro .pro-icon ul li::before {
	background: url(../../media/images/icon/li-border.png);
	background-size: auto;
	content: '';
	width: 5px;
	height: 47px;
	position: absolute;
	top: 10px;
	right: -18px;
	background-repeat: no-repeat;
}
.sin-product.list-pro .pro-icon ul li:last-child:before {
	display:none;
}

.sin-product.list-pro  .pro-icon ul li::after {
	display: none;

}
.sin-product.list-pro  .pro-icon ul li {
	margin: 0px 12px;
}
.sin-product.list-pro .new-tag {
	color: #fff;
	background: #66a2f4;
	padding: 2px 8px;
	font-size: 14px;
	position: absolute;
	top: 18px;
	left: 33px;
}

.sort-by {
	text-align: right;
	span{
		font-size:16px;
		color:#7b7b7b;
		margin-right: 15px;
		font-weight: 500;
	}
	select{
		border:1px solid #e9e9e9;
		width:200px;
		height:30px;
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
		appearance: none;
		background:rgba(0, 0, 0, 0) url("../../media/images/icon/arrow.png") no-repeat scroll 94% 47% ;
		color:#7b7b7b;
		padding-left:15px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 25px;
	}
}
.sort-by select option {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}



/*--------------------------------------------------------------
  ##  Product Page
  --------------------------------------------------------------*/


  .zoom-slider.owl-theme .owl-nav [class*="owl-"] {
  	color: #979fa2;
  	font-size: 23px;
  	margin: 5px;
  	padding: 0px 6px;
  	background: transparent;
  	display: inline-block;
  	cursor: pointer;
  	border-radius: 1px;
  	position: absolute;
  	top: 37%;
  	line-height: 0px;
  }
  .zoom-slider.owl-theme .owl-nav .owl-next{right: 0px;opacity: 1;}
  .zoom-slider.owl-theme .owl-nav .owl-prev{left: 0px;opacity: 1}
  .zoom-slider.owl-theme .owl-nav {margin-top: 0px;}
  .zoom-slider.owl-theme:hover .owl-nav .owl-next {
  	-webkit-transition: all 0.5s ease 0s;
  	transition: all 0.5s ease 0s;
  	opacity: 1;
  }
  .zoom-slider.owl-theme:hover .owl-nav .owl-prev {
  	-webkit-transition: all 0.5s ease 0s;
  	transition: all 0.5s ease 0s;
  	opacity: 1;
  }
  .zoom-slider .item {
  	border: 1px solid #f1f1f1;
  	margin: 17px;
  }

  .product-details{
  	padding-left: 30px;
  	.pro-title{
  		a{
  			@include WorkSans-semi-bold;
  			color: $title_color;
  			font-size:30px;
  			margin-bottom: 0px;
  		}
  	}
  	span{
  		font-size: 16px;
  		color: #636363;
  		padding-bottom: 5px;
  		display: inline-block;
  		text-transform: capitalize;
  		margin-right: 15px;
  	}
  	p{
  		font-size: 16px;
  		color: #636363;
  		padding: 45px 0px 20px;

  		a{
  			font-size:16px;
  			color:$fifth_color;

  		}
  	}
  	.price{
  		font-size: 20px;
  		@include WorkSans-semi-bold;
  		color: #464646;
  		padding: 10px 0px 40px;
  	}
  	.color-variation {
  		float: none;
  		padding: 40px 0px;
  		display:block;
  		span{
  			display:inline;
  			float:left;
  			margin-right: 10px;

  		}
  	}
  	ul{
  		padding-left: 24px;
  		list-style: inherit;
  		padding-bottom: 20px;
  		li{
  			margin-bottom: 5px;
  		}
  	}
  }

  .add-tocart-wrap{
  	width: 100%;
  	overflow: hidden;
  	display: block;
  	span{
  		float: left;
  		display: block;
  		padding-top: 18px;
  		margin-right: 16px;
  		font-weight: 500;
  		font-size: 18px;
  	}
  	select{
  		height: 60px;
  		width: 60px;
  		color: #fff;
  		border: none;
  		-moz-appearance: none;
  		padding-left: 10px;
  		font-size: 18px;
  		font-weight: 600;
  		background:#464646 url("../../media/images/icon/arrow-w.png") no-repeat scroll 29px 25px;
  		float:left;
  		margin-right: 6px;
  	}
  	a.add-to-cart{
  		height: 60px;
  		width: 325px;
  		background: $main_color;
  		color: #fff;
  		font-size: 18px;
  		float: left;
  		margin-right: 6px;
  		padding: 17px;
  		text-align: center;
  		i{
  			margin-right: 10px;
  		}
  		&:hover{
  			background:$second_color;
  		}
  	}
  	a {
  		height:60px;
  		width:60px;
  		float:left;
  		color : #fff;
  		background:#464646;
  		text-align: center;
  		padding:17px 0px
  	}
  }

  .product-social{
  	span{
  		float: left;
  		display: block;
  		padding-top: 6px;
  		margin-right: 16px;
  		font-weight: 500;
  		font-size: 18px;

  	}
  	ul{
  		li{
  			display: inline-block;
  			width: 40px;
  			height: 40px;
  			text-align: center;
  			line-height: 40px;
  			font-size: 18px;
  			-o-transition: all 0.3s;
  			-webkit-transition: all 0.3s;
  			transition: all 0.3s;
  			border-radius: 8px;
  			margin-right: 5px;
  			@include transition(all 0.3s);

  			a{
  				display:block;
  				width:100%;
  				color : #464646;
  				&:hover{
  					color: $main_color;
  				}
  			}
  		}
  	}
  }


  .product-des-tab{
  	> ul{
  		border-bottom: 1px solid #ccc;
  		.nav-item{
  			position:relative;
  			margin-right: 31px;

  			a{
  				color:#727171;
  				font-size:18px;
  				text-transform:uppercase;
  				position:relative;
  			}
  		}
  	}
  	.prod-bottom-tab-sin.description{
  		ul{
  			padding-left: 20px;
  			padding-bottom:15px;
  			li{
  				padding: 5px 0;
  				font-size:16px;
  				color:#636363;
  				position: relative;
  				&:after{
  					position: absolute;
  					content: '\f00c';
  					top: 9px;
  					left: -20px;
  					font-family: 'Font Awesome 5 Free';
  					font-weight: 900;
  					font-size: 12px;
  					color: #d19e66;
  				}
  			}
  		}
  	}
  }
  .product-des-tab .nav-tabs .nav-item.show .nav-link, .product-des-tab .nav-tabs .nav-link.active {
  	color: #495057;
  	background-color: #fff;
  	border-bottom: 1px solid #ccc;

  }
  .product-des-tab .nav-tabs .nav-item a:hover:after,.product-des-tab .nav-tabs .nav-item a.active:after{
  	position: absolute;
  	content: '';
  	width: 60px;
  	height: 4px;
  	background: #d19e66;
  	bottom: -2px;
  	left: 50%;
  	margin-left: -30px;
  }
  .prod-bottom-tab-sin{
  	padding-top: 40px;
  	h5{
  		font-size:18px;
  		color:#464646;
  		margin-bottom: 20px;

  	}
  	p{
  		font-size:16px;
  		color:#636363;
  		line-height:28px;
  	}
  }
  .info-wrap{
  	.sin-aditional-info{
  		width: 100%;
  		overflow: hidden;
  		.first{
  			width: 30%;
  			float: left;
  			border-right: 1px solid #d6d4d3;
  			border-left: 1px solid #d6d4d3;
  			padding: 11px 24px;
  			color: #636363;
  		}
  		.secound {
  			padding: 11px 24px;
  			width: 70%;
  			overflow: hidden;
  		}

  	}

  }

  .sin-aditional-info:nth-child(odd){
  	background:#edebec;
  }


  .size-variation{
  	select{
  		-webkit-appearance: none;
  		-moz-appearance: none;
  		appearance: none;
  		background-position: right center;
  		background-image: url(../../media/images/icon/size-arrow.png);
  		background-repeat: no-repeat;
  		background-position: right 10px center;
  		line-height: 1.2;
  		padding-right: 28px;
  		cursor: pointer;
  		padding-top: 8px;
  		padding-left: 15px;
  		padding-bottom: 8px;
  		border: 1px solid #ccc;
  		width: 25%;
  		option{

  		}
  	}
  }

  /*----plus minus--------*/
  .cart-plus-minus {
  	background: #fff repeat scroll 0 0;
  	border: 1px solid #dadada;
  	height: 60px;
  	text-align: center;
  	width: 59px;
  }
  .dec {
  	background: url(../../media/images/icon/less.png)no-repeat scroll 50% 50%;
  	float: left;
  	font-size: 28px;
  	line-height: 19px;
  	position: relative;
  	text-indent: 99999px;
  	border-right: none !important;
  }
  .inc {
  	background: url(../../media/images/icon/add.png)no-repeat scroll 50% 50%;
  	float: right;
  	position: relative;
  	text-indent: 99999px;
  	border-left: none !important;
  }
  .qtybutton {
  	border: 1px solid #dadada;
  	color: #c0c0c0;
  	cursor: pointer;
  	display: block;
  	font-size: 24px;
  	height: 60px;
  	line-height: 24px;
  	padding: 0;
  	text-align: center;
  	vertical-align: middle;
  	width: 45px;
  }
  .cart-plus-minus-button {
  	background: #f1f1f1 none no-repeat scroll 0 0;
  	display: inline-block;
  	float: left;
  	margin-right: 25px;
  	width: 149px;
  	overflow: hidden;
  }

  .product-des-tab{
  	padding-top:80px;
  }

  .product-review{
  	.reviwer{
  		overflow:hidden;
  		img{
  			weight:110px;
  			border:5px solid #fff;
  			float: left;

  		}
  		.review-details{
  			width: 86%;
  			overflow: hidden;
  			padding-left: 20px;
  			padding-top: 15px;
  			span{
  				font-size:16px;
  				color:#9f9d9d

  			}
  			.rating{
  				padding: 10px 0px;
  				a{
  					color: $main_color;
  					font-size:18px;
  				}
  			}
  			p{

  			}
  		}
  	}
  }
  .add-your-review{
  	padding-top:50px;
  	h6{
  		font-size:16px;
  		color: #515151;
  	}
  	p{
  		font-size:16px;
  		color: #515151;
  		float:left;
  		display:inline-block;
  		margin-right: 10px;
  	}
  	.rating{
  		a{
  			color: $main_color;
  			font-size:18px;
  		}

  	}
  }
  .raing-form{
  	overflow: hidden;
  	display: block;
  	width: 100%;
  	input{
  		width: 48%;
  		margin-right: 2%;
  		height: 44px;
  		background: #f4f4f4;
  		float: left;
  		border: none;
  		margin-bottom: 30px;
  		display: inline-block;
  		padding-left:15px;
  	}
  	textarea{
  		width: 98%;
  		margin-right: 2%;
  		height:200px;
  		background: #f4f4f4;
  		float: left;
  		border: none;
  		margin-bottom: 30px;
  		display: inline-block;
  		padding:15px;
  	}
  	input[type="submit"]{
  		@include WorkSans-semi-bold;
  		color:#fff;
  		background: $main_color;
  		padding:10px 15px;
  		width:204px;
  		cursor:pointer;
  	}
  }
  #gallery_01 {
  	margin-top: 24px;
  	overflow: hidden;
  	margin-left: 6px;
  }
  .zoom-slider .owl-carousel .owl-item {
  	padding: 0 10px;
  }
  .inner-wrapper {
  	padding-top: 30px;
  }

 .load-more {
	background: transparent;
	color: #3f3f3f;
	font-size: 15px;
	padding: 12px 20px;
	width: 150px;
	font-weight: 500;
	cursor: pointer;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	text-align: center;
	border: 2px solid $main_color;
}

 .load-more:hover {
	color:$main_color;
}
.load-more-wrapper{
	width:100%;
	text-align:center;
	padding-top: 45px;
}
.advertise-img{
	text-align: center;
}
.shop-area.single-product {
	padding-bottom: 0px;
}
.shop-sidebar.left-side {
	padding-right: 15px;
}
