
.top-bar{
	@include box-shadow(0px, 0px, 4px, 0px , rgba(101, 114, 129, 0.29));
	background:#f6f6f6;
	padding:15px 0px 3px;
}
.top-bar-left{
	p{
		font-size:15px;
		color: #747474;
		float:left;
		margin-right: 40px;
		position:relative;
		a {
			color: #747474;
			&:hover{
				color: $main_color;
			}
		}
		i {
			margin-right:5px;
			color: $main_color;
		}
	}
	> a{
		font-size:15px;
		color: #747474;
		float:left;
		margin-right: 40px;
		position:relative;
		&:hover{
			color: $main_color;
		}
		i {
			margin-right:5px;
		}

	}
}


.top-bar-left p:not(:last-child)::after {
	content: '';
	position: absolute;
	width: 2px;
	background: #cccccc;
	height: 14px;
	top: 4px;
	right: -21px;
}
.top-bar-right{
	> a {
		color: $main_color;
		font-weight:500;
		float: right;
		padding-right: 33px;
		&:hover{
			color: $main_color;
		}
}
	.social{
		float: right;
		ul{
			li{
				margin-right: 18px;
			}
			a{
			color:#747474 ;
			&:hover{
				color: $main_color;
			}
		}


		}
	}
}



.mainmenu.style-two ul li::before {
	position: absolute;
	content: '\f078';
	top: 27px;
	right: 13px;
	font-family: 'Font Awesome 5 Free';
	font-size: 12px;
	display:none;
}
.mainmenu.style-two ul li a {
	@include WorkSans-medium;

}
.mainmenu.style-two {
	float: right;
}
.header-right-menu{
	padding: 38px 0px;
	float:right;
	 > ul{
		> li{
			display: inline;
			padding: 0px 15px;
			border-right: 1px solid #ddd;
			&:last-child{
				border-right:none;
			}
			a{
				color: $second_color;
				font-size:15px;
			&:hover{
				color: $main_color;
			}
			}
		}
	}
}
.top-search.style-two {
	border-right: 1px solid #ddd;
	position: relative;
}

.top-cart a > span {
	position: absolute;
	top: -9px;
	right: 4px;
	font-size: 12px;
	color: #fff;
	background: #747474;
	height: 15px;
	width: 15px;
	border-radius: 50px;
	text-align: center;
	line-height: 15px;
}

/*--------------------------------------------------------------
  ##  Navbar Three Css
  --------------------------------------------------------------*/
.header-area.style-three {
	position: absolute;
	top: 30px;
	left: 0;
	z-index: 99999;
	width: 100%;
	background: none;
	padding: 0 30px;
}
.headroom--pinned.header-area.style-three{
	position:fixed;
	background:#fff;
}
.header-right-menu.style-three{
	padding: 33px 0px;
	float:right;
	 > ul{
		> li{
			float: left;
			padding: 0px 17px;
			border-right: 1px solid #6a6a6a;
			display: inline;
			&:last-child{
				border-right:none;
				padding: 0px 10px;
			}
			> a{
				font-size:20px;
				img{
					border-radius: 100%;
				}
			}
		}
	}
}

.mainmenu.style-three {
	visibility: hidden;
	opacity: 0;
	@include transition(opacity 0.3s ease .5s);
	 transition-delay: .3s;
}
.mainmenu.style-three.active{
	visibility:visible;
	opacity:1;
}
.header-area.style-three::after {
	position: absolute;
	content: '';
	background: white;
	width: 0;
	height: 105px;
	top: 0px;
	z-index: -1;
	@include transition(all 0.5s ease 0s);
	right:0;
}
.menu-open .header-area.style-three::after{
	width:100%;
}
.mainmenu.style-three > ul > li:nth-child(7) {
	-webkit-transition-delay: .30s;
	transition-delay: .30s;
}
.mainmenu.style-three > ul > li:nth-child(6) {
	-webkit-transition-delay: .35s;
	transition-delay: .35s;
}
.mainmenu.style-three > ul > li:nth-child(5) {
	-webkit-transition-delay: .40s;
	transition-delay: .40s;
}
.mainmenu.style-three > ul > li:nth-child(4) {
	-webkit-transition-delay: .45s;
	transition-delay: .45s;
}
.mainmenu.style-three > ul > li:nth-child(3) {
	-webkit-transition-delay: .50s;
	transition-delay: .50s;
}
.mainmenu.style-three > ul > li:nth-child(2) {
	-webkit-transition-delay: .55s;
	transition-delay: .55s;
}
.mainmenu.style-three > ul > li:nth-child(1) {
	-webkit-transition-delay: .60s;
	transition-delay: .60s;
}
.mainmenu.style-three ul li a{font-size:15px;}

.flaticon-tool{display:block;}
.active .flaticon-tool{display:none}
.menu-btn .flaticon-menu-1{display: block;
	font-size: 21px;}

	.menu-btn .active .flaticon-menu-1{display:none}

	.menu-btn .flaticon-close{display:none;}
	.menu-btn .active .flaticon-close{display:block;font-size:21px;}

.header-right-menu.style-three .top-cart {
	width: auto;
}

.home-version-3{
	padding:30px;
}