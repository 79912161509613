// Extra large devices (large desktops, 1200px and up)

@media (min-width: 1200px) and (max-width: 1500px) {

	/*  Index one  */

	.mainmenu ul li a {
		padding: 25px 14px;
	}
	.mainmenu li.has-child::before {
		right: 0;
	}
	.custom-select {
		font-size: 14px;
	}
	.header-right-one ul li {
		padding: 0 6px;
	}
	.sin-banner-con {
		padding-top: 10px;
	}
	.sin-banner-con {
		width: 72%;
	}
	.sin-banner .sin-banner-con.style-two {
		padding-top: 56px;
		width: 72%;
	}
	.sin-feature .inner-sin-feature {
		top: 56%;
		padding-left: 22px;
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		right: 40px;
	}
	.slider-text h1 {
		font-size: 50px;
	}
	.slider-text.style-two h1 {
		font-size: 50px;
		padding-bottom: 10px;
	}
	.slider-text p {
		font-size: 16px;
	}
	.slider-text.style-two h1 {
		font-size: 50px;
		font-family: 'Work Sans', sans-serif;
		font-weight: 700;
	}
	.color-widget ul li {
		width: 45px;
		height: 45px;
	}
	.sin-product h5.pro-title a {
		font-size: 18px;
	}
	.list-pro-det span {
		font-size: 21px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.list-pro-det .rating a {
		font-size: 14px;
	}
	.list-pro-det p {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 5px;
	}
	.list-pro-det a.btn-two {
		font-size: 16px;
		padding:9px 36px;
	}
	.list-pro-det .color-variation {
		margin: 9px 0px 5px;
	}
	.list-pro-det a.btn-two::before,.list-pro-det a.btn-two::after  {
		height: 52px;
	}
	.product-details {
		padding-left: 0px;
	}
	.product-details .color-variation {
		padding: 15px 0px;
	}
	.product-details .price {
		padding: 10px 0px 10px;
	}
	.product-details p {
		font-size: 15px;
		padding: 25px 0px 10px;
	}
	.product-des-tab {
		padding-top: 40px;
	}
	.shop-area {
		padding: 50px 0 80px;
	}
	.pro-icon ul li {
		margin: 0 25px;
	}
	.section-heading p {
		padding: 0 20%;
	}
	.mainmenu .mega-button a {
		font-size: 14px;
		padding: 3px 15px;
	}

	.five-col .mega-product {
		padding: 0 15px;
	}
	.mega-product .sin-product h5.pro-title a {
		font-size: 14px;
	}
	.mega-product .sin-product span {
		font-size: 15px;
		margin-top: 3px;
	}
	.shop-four-grid .sin-product .color-variation{
		float: none;
	}
	.shop-four-grid .sin-product .pro-icon ul li {
		margin: 0 5px;
	}
	.mainmenu li.has-child > a::before {
		right: 0;
	}
	.sin-product.style-two .mid-wrapper span {
		text-align: center;
	}
	.sin-product.style-two .pro-title a {
		text-align: center;
	}
	.sin-product.style-two .pro-title {
		text-align: center;
		width: 100%;
	}
	.color-variation {
		float: none;
		text-align: center;
	}
	.sin-product.style-one h5.pro-title {
		width: 100%;
		text-align: center;
	}
	.sin-product.style-one span {
		float: none;
	}
	.shop-sidebar.left-side {
		padding-right: 0;
	}
	.padding-top-120 {
		padding-top: 80px;
	}
	.main-product {
		padding: 65px 0 25px;
	}
	.banner-product {
		padding: 70px 0 40px;
	}
	.small-pro-details .title {
		font-size: 16px;
	}
	.product-small {
		padding: 75px 0 50px;
	}
	a.btn-two {
		padding: 8px 27px;
	}
	a.btn-two::before , a.btn-two::after {
		height: 56px;
	}
	.sin-feature .inner-sin-feature {
		top: 50%;
		padding-left: 6px;
	}

	.padding-top-90 {
		padding-top: 50px;
	}
	.sin-product.style-two.small .pro-icon ul li {
		margin: 0 12px;
	}
	.footer-widget-area {
		padding: 80px 0 0;
	}
	.padding-120 {
		padding: 80px 0 !important;
	}
	.load-more-wrapper {
		padding-top: 25px;
	}
	.sidebar-widget.sidebar-search {
		padding-bottom: 30px;
	}
	.shop-sorting-area.row {
		margin-bottom: 30px;
	}
	.main-product.padding-120 {
		padding-bottom: 20px !important;
	}
	.add-tocart-wrap a.add-to-cart {
		width: 235px;
	}
	.subscribe-wrapper {
		width: 530px;
	}

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {


	.mainmenu > ul {
		padding: 0px 0px;
		border-top: 1px solid #ddd;
	}
	.logo {
		padding: 20px;
	}
	.header-right-one {
		padding-top: 22px;
	}
	.sin-banner-con {
		padding-top: 35px;
	}
	.sin-banner-con {
		width: 72%;
	}
	.sin-banner-con.style-two {
		padding-top: 56px;
		width: 72%;
	}
	.sin-banner-con h4 {
		font-size: 15px;
	}
	.sin-banner-con h4 span {
		font-size: 15px;
	}
	.sin-banner-con h4 {
		line-height: 25px;
	}
	.sin-banner-con h3 {
		font-size: 28px;
	}
	.sin-banner-con p {
		margin-top: 24px;
		margin-bottom: 0px;
	}
	.sin-banner-con span {
		font-size: 17px;
		line-height: 21px;
	}
	.sin-banner-con-right {
		top: 100px;
	}
	.subscribe-text p {
		font-size: 14px;
		line-height: 24px;
	}
	.subscribe-text h6 {
		font-size: 18px;
	}
	.subscribe-wrapper {
		width: 533px;
		margin-top: 27px;
	}
	.subscribe-wrapper button {
		height: 55px;
	}
	.subscribe-wrapper input {
		height: 55px;
	}
	.footer-widget h3 {
		font-size: 20px;
	}
	.footer-widget p {
		font-size: 15px;
		padding-right: 20px;
	}
	.footer-menu ul li {
		margin-bottom: 5px;
	}
	.payment-link li {
		width: 23%;
	}
	.instagram-area {
		padding: 5px 0 5px;
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		right: 40px;
	}

	.search-input {
		top: 47px;
	}

 	/*  Header area two */

 	.mainmenu.style-two {
 		float: none;
 	}
 	.header-right-menu {
 		padding: 24px 0px 18px;

 	}
 	.pb-info::after {
 		width: 220px;
 	}
 	.pb-info {
 		width: 200px;
 		bottom: 30px;
 	}
 	.pro-icon ul li {
 		margin: 0 12px;
 	}
 	.slider-text h1 {
 		font-size: 45px;
 	}
 	.slider-text p {
 		font-size: 16px;
 	}
 	.header-area.style-three::after {
 		height: 175px;
 	}
	/*  Home page three */
	.sin-category .cat-name a p {
		line-height: 20px;
	}
	.sin-category .cat-name a {
		font-size: 14px;
		padding:6px 17px;
	}
	.sin-category .cat-name {
		margin-top: -40px;
	}
	.sin-product.style-three .pro-icon::after {
		width: 64%;
	}
	.single-testimonial .tes-img {
		width: 80px;
		height: 80px;
		border: 3px solid #fff;
	}
	.single-testimonial .tes-content p {
		font-size: 14px;
		line-height: 24px;
	}
	.main-product .grid-three .grid-item:first-child {
		margin-top: 250px;
	}
	.main-product .grid-three .grid-item {
		margin-top: 100px;
	}
	.footer-bottom p {
		font-size: 14px;
	}
	.footer-payment-icon ul li {
		margin-left: 2px;
		width: 11%;
		float: left;
	}
	.footer-bottom {
		padding: 18px 0 14px;
		margin-top: 45px;
	}
	.footer-widget-area.style-two .footer-bottom {
		padding: 29px 0 14px;

	}
	.footer-bottom p {
		margin-top: 5px;
	}
	.instagram-area-two .sin-instagram .hover-text span {
		font-size: 18px;
		padding: 8px 20px;
	}
	/*  Shop page  */
	.wid-pro .sp-img {

		width: 75px;
		margin-top: 5px;
	}
	.list-pro-det span {
		font-size: 24px;
		margin-top: 0px;
		margin-bottom: 4px;

	}
	.list-pro-det p {
		font-size: 15px;
		line-height: 26px;
		margin-bottom: 23px;
	}
	.list-pro-det span {
		font-size: 21px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.list-pro-det .rating a {
		font-size: 14px;
	}
	.list-pro-det p {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 5px;
	}
	.list-pro-det a.btn-two {
		font-size: 16px;
		padding:9px 36px;
	}
	.list-pro-det .color-variation {
		margin: 9px 0px 5px;
	}
	.list-pro-det a.btn-two::before,.list-pro-det a.btn-two::after  {
		height: 52px;
	}
	.breadcrumb-area {
		background: #edebec;
		padding: 210px 0px 10px;
	}
	/*  Product  page  */
	.product-details {
		padding-left: 0px;
	}
	.product-details .color-variation {
		padding: 15px 0px;
	}
	.product-details .price {
		padding: 10px 0px 10px;
	}
	.product-details p {
		font-size: 15px;
		padding: 25px 0px 10px;
	}
	.product-des-tab {
		padding-top: 40px;
	}
	.shop-area {
		padding: 50px 0px 80px;
	}
	.add-tocart-wrap a.add-to-cart {
		height: 45px;
		width: 169px;
		font-size: 18px;
		float: right;
		margin-right: 5px;
		padding: 8px;
	}
	.qtybutton {
		height: 45px;
	}
	.cart-plus-minus {
		height: 45px;
	}
	.cart-plus-minus-button {
		margin-right: 18px;
	}
	.section-heading p {
		padding: 0 15%;
		padding-bottom: 10px;
	}
	.sidebar-search input {
		width: 80%;
	}
	.sidebar-search button {
		width: 20%;
	}
	.mainmenu li:hover .mega-menu {
		top: 100%;
	}

	.mainmenu li:hover .sub-menu {
		top: 100%;
	}
	.modal.popup-1 .modal-dialog ,.modal.popup-2 .modal-dialog{
		max-width: 850px;
	}
	.modal.popup-1,.modal.popup-2 {
		top: 185px;
	}
	.five-col .mega-product {
		padding: 0 10px;
	}
	.mega-product .sin-product h5.pro-title a {
		font-size: 15px;
	}
	.mega-product .sin-product span {
		font-size: 15px;
		float: left;
	}
	.mainmenu .mega-button a {
		font-size: 13px;
	}
	.mainmenu .mega-button a::before {
		top: 7px;
	}
	.quickview-wrapper {
		width: 900px;
		height: 400px;
		overflow: scroll;
	}

	.slider-1 {
		padding-top: 125px;
	}
	.slider-2 {
		padding-top: 180px;
	}
	.slider-text.style-two h1 {
		font-size: 45px;
		padding-bottom: 0;
	}

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {

	.sin-banner-con h4 {
		line-height: 20px;
	}
	.sin-banner-con h4{
		font-size: 14px;
	}
	.sin-banner-con {
		padding-top: 5px;
		width:60%;
	}
	.sin-banner-con h4 span {
		font-size: 14px;
	}
	.sin-banner-con .banner-top::after {
		left: 0;
		width: 100%;
		margin-left: 0;
	}
	.sin-banner-con span {
		font-size: 14px;
		line-height: 20px;
	}
	.sin-banner-con h3 {
		font-size: 18px;
	}
	.sin-banner-con p {
		font-size: 14px;
		margin-top: 0px;
	}
	.sin-banner .sin-banner-con.style-two {
		padding-top: 22px;
		width: 65%;
		padding: 20px 5px;
	}
	.sin-banner .sin-banner-con.style-two h4 a {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.sin-banner .sin-banner-con.style-two span {
		font-size: 14px;
	}
	.sin-banner.borders {
		border: 2px solid #233e47;
	}
	.sin-banner.borders:hover {
		border: 2px solid #d19e66;
	}
	.sin-banner-con-right p {
		font-size: 15px;
		font-weight: 400;
	}
	.sin-banner-con-right {
		padding: 4px 29px;
		top: 36%;
		margin-right: -63px;
	}
	.section-heading p {
		padding: 0 5%;
	}
	.instagram-area {
		padding: 5px 0 5px;
	}
	.footer-widget {
		margin-bottom: 40px;
	}
	.footer-widget-area {
		padding: 65px 0 20px;
	}
	.footer-widget-area.style-two .footer-bottom p {
		text-align: center;
	}
	.subscribe-text {
		text-align: center;
		padding: 0 100px;
	}
	.subscribe-wrapper {
		width: 660px;
		float: none;
		margin: 30px auto 0;
	}
	.pro-tab-filter .pro-tab-button li {
		margin: 0 14px;
	}
	.mainmenu{display:none}
	#header {
		display: none;
	}
	.slider-text h1 {
		font-size: 42px;
		margin: 6px 0px;
	}
	.slider-text p {
		font-size: 14px;
	}
	a.btn-two::after,a.btn-two::before{
		height: 55px;
	}
	a.btn-two {
		padding: 7px 26px;

	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		top: 37%;
		right: 70px;
	}

	.slider-text p {
		display: none !important;
	}
	.slider-text.style-two h1 {
		font-size: 35px;
		padding-bottom: 0;
	}
	.main-product .grid-three .grid-item {
		padding: 0 20px;
	}
	.main-product .grid-three .grid-item {
		margin-top: 30px;
	}
	.main-product .grid-three .grid-item:first-child {
		margin-top: 100px;
	}
	.padding-120 {
		padding: 80px 0!important;
	}
	.main-product {
		padding: 70px 0 20px;
	}
	.banner-product {
		padding: 70px 0 35px;
	}
	.product-small {
		padding: 70px 0 45px;
	}
	.prod-banner-two {
		margin-bottom: 30px;
	}
	.padding-top-90{
		padding-top:50px;
	}
	.padding-150 {
		padding: 80px 0;
	}
	.feature-area {
		padding: 80px 0;
	}
	.pb-30 {
		padding-bottom: 20px;
	}
	.footer-bottom {
		margin-top: 30px;
	}
	.subscribe-area.style-two .subscribe-text {
		margin-top: 0px;
		margin-bottom: 20px;
	}
	.subscribe-text {
		padding: 0 50px;
	}
	.category-area.padding-120 {
		padding-bottom: 50px!important;
	}
	.sin-product.style-three .pro-icon::after {
		width: 65%;
	}
	.list-pro-det span {
		font-size: 24px;
		margin-top: 0px;
		margin-bottom: 4px;
	}
	.list-pro-det p {
		font-size: 15px;
		line-height: 26px;
		margin-bottom: 23px;
	}
	.list-pro-det span {
		font-size: 21px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.list-pro-det .rating a {
		font-size: 14px;
	}
	.list-pro-det p {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 5px;
	}
	.list-pro-det a.btn-two {
		font-size: 16px;
		padding:9px 36px;
	}
	.list-pro-det .color-variation {
		margin: 9px 0px 5px;
	}
	.list-pro-det a.btn-two::before,.list-pro-det a.btn-two::after  {
		height: 52px;
	}
	/*  Shop page  */
	.shop-area {
		padding: 50px 0px 0px;
	}
	.product-des-tab > ul .nav-item {
		margin-right: 0px;
	}
	.product-details {
		padding-left: 15px;
		padding-top: 30px;
	}
	.product-details .color-variation {
		padding: 15px 0px;
	}
	.product-details .price {
		padding: 10px 0px 10px;
	}
	.product-details p {
		font-size: 15px;
		padding: 25px 0px 10px;
	}
	.product-des-tab > ul .nav-item a {
		font-size: 14px;
	}
	.shop-area .wid-pro .sp-img {
		width: 70px;
	}
	.gmap3-area {
		height: 400px;
	}
	.contact-info {
		padding-top: 90px;
	}
	.quickview-wrapper {
		width: 93%;
		height: 510px;
		overflow: scroll;
	}

	.quickview .add-tocart-wrap a.add-to-cart {
		height: 45px;
		width: 169px;
		font-size: 18px;
		float: right;
		margin-right: 5px;
		padding: 8px;
	}
	.quickview .qtybutton {
		height: 45px;
		width:40px;
	}
	.quickview .cart-plus-minus {
		height: 45px;
		width:40px;
	}
	.quickview .cart-plus-minus-button {
		margin-right: 0px;
	}
	.quickview .cart-plus-minus-button {
		width: 120px;
	}
	.quickview .product-details .pro-title a {
		font-size: 23px;
	}
	.quickview .product-details {
		padding-left: 0px;
		padding-top: 5px;
	}

	.modal.popup-1 .modal-dialog ,.modal.popup-2 .modal-dialog{
		max-width: 700px;
	}
	.modal.popup-1,.modal.popup-2 {
		/* top: 185px; */

	}
	.pb-info {
		bottom: 13px;
		left: 0;
		width: 190px;
		height: 126px;
	}
	.pb-info::after {
		width: 110%;
		height: 116%;
	}
	.padding-top-120 {
		padding-top: 80px;
	}
	.load-more-wrapper {
		padding-bottom: 50px;
	}
	.main-product.bg-one::before {
		height: 230px;
	}

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {

	#header {
		display: none;
	}

	.footer-widget {
		margin-bottom: 40px;
	}
	.footer-widget-area {
		padding: 50px 0 0;
	}
	.sin-banner {
		border: 2px solid #233e47;
		margin-bottom: 30px;
	}
	.sin-banner:hover {
		border-color: #d19e66;
	}
	.pro-tab-filter .pro-tab-button li {
		font-size: 14px;
		margin: 0 9px;
	}
	.sin-feature .inner-sin-feature {
		top: 50%;
		padding: 0px 10px;
	}
	.section-heading p {
		padding: 0 5%;
	}
	.small-pro-details .title {
		font-size: 15px;
	}
	.subscribe-text {
		text-align: center;
		margin-bottom: 30px;
	}
	.subscribe-wrapper {
		width: 490px;
		float: none;
		margin: 0 auto;
	}
	.instagram-area {
		padding: 25px 0 6px;
	}
	.pro-icon ul li {
		margin: 0 22px;
	}
	.sin-feature .f-content h6 {
		font-size: 15px;
	}
	.sin-feature .icon {
		width: 25%;
	}
	.sin-feature .f-content {
		width: 75%;
	}
	.sin-feature {
		height: 147px;
	}
	.sin-feature .inner-sin-feature {
		top: 57%;
	}
	.slider-text h1 {
		font-size: 30px;
		margin: 5px 0;
		line-height: 40px;
	}
	.slider-text h4 {
		font-size: 20px;
	}
	.slider-text p {
		display: none !important;
	}

	.slider-text.style-two h1 {
		font-size: 25px;
		padding-bottom: 0;
	}
	.slider-wrapper {
		padding-top: 54px;
	}
	a.btn-two {
		font-size: 14px;
		padding: 6px 24px;

	}
	a.btn-two::before {
		width: 20px;
		height: 41px;
		top: -4px;
		left: -4px;
	}
	a.btn-two::after {
		width: 20px;
		height: 41px;
		top: -4px;
		right: -4px;
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		top: 34%;
		right: 70px;
	}
	.slider-text.pr-rem {
		padding-right: 0;
		text-align: right;
		padding-left: 50px;
	}

	.shop-four-grid .sin-product {
		margin-bottom: 50px;
	}
	.padding-50 {
		padding: 30px 0;
	}
	.feature-area {
		padding: 30px 0;
	}
	.padding-120 {
		padding: 50px 0!important;
	}
	.padding-top-90{
		padding-top: 0;
	}
	.pb-30 {
		padding-bottom: 0px;
	}
	.category-area.padding-120 {
		padding-bottom: 0px;
	}
	.main-product .grid-three .grid-item:first-child {
		margin-top: 50px;
	}
	.main-product .grid-three .grid-item {
		margin-top: 100px;
	}
	.sidebar-search button {
		width: 10%;
	}
	.sidebar-search input {
		width: 90%;
	}
	.sin-product.list-pro {
		width: 65%;
		margin: 0 auto;
		margin-bottom: 40px;
	}
	.list-pro .list-pro-det {
		margin-top: 40px;
	}
	.breadcrumb-area {
		padding: 20px 0px 10px;
	}
	.shop-area {
		padding: 50px 0px 0px;
	}
	.shop-sorting-area.row {
		margin-bottom: 50px;
	}
	/*  Shop page  */
	.shop-area {
		padding: 50px 0px 0px;
	}
	.product-des-tab > ul .nav-item {
		margin-right: 0px;
	}
	.product-details {
		padding-left: 15px;
		padding-top: 30px;
	}
	.product-details .color-variation {
		padding: 15px 0px;
	}
	.product-details .price {
		padding: 10px 0px 10px;
	}
	.product-details p {
		font-size: 15px;
		padding: 25px 0px 10px;
	}
	.footer-widget-area.style-two .footer-bottom p {
		text-align: center;
	}
	.product-des-tab > ul .nav-item a {
		font-size: 16px;
	}
	.cart-table .tables tbody tr td:nth-child(3) {
		width: 63px;
	}
	.cart-table .tables tbody tr td:nth-child(2) {
		width: 50px;
	}
	.cart-table .tables tbody tr td:nth-child(3) {
		width: 200px;
	}
	.cart-table .tables tbody tr td .product-title a {
		font-size: 14px;
	}
	.cart-btn-section .coupon-code {
		margin-bottom: 15px;
	}
	.footer-bottom {
		margin-top: 25px;
		padding: 40px 0 35px;
	}
	.gmap3-area {
		height: 300px;
	}
	.contact-info {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.modal.quickview-wrapper {
		width: 500px;
		height: 400px;
		overflow: scroll;
	}
	.modal.popup-1 .modal-dialog ,.modal.popup-2 .modal-dialog{
		max-width: 500px;
		max-height: 300px;
	}
	.popup-banner p {
		font-size: 13px;
		padding: 0;
	}
	.popup-1 .popup-banner {
		height: 400px;
	}
	.modal  .subscribe-wrapper input {
		height: 50px;
	}
	.modal  .subscribe-wrapper button {
		height: 50px;
	}
	.popup-banner.style-two {
		padding-right: 0;
	}
	.pb-info {
		width: 220px;
		bottom: 15px;
	}
	.pb-info {
		left: 0px;
	}
	.pb-info::after {
		width: 240px;
		border: 3px solid #4f4f50;
	}
	.prod-banner-two {
		margin-top: 25px;
	}
	.banner-product .prod-banner-two {
		margin-bottom: 30px;
	}
	.view-all {
		top: 105px;
		left: 85px;
	}
	.padding-top-120 {
		padding-top: 50px;
	}
	.slider-text {
		padding-right: 0px;
		text-align: center;
	}
	.main-product {
		padding: 40px 0 50px;
	}
	.banner-product {
		padding: 40px 0 0px;
	}
	.product-small {
		padding: 40px 0 0;
	}
	.load-more-wrapper {
		padding: 50px 0;
	}
	.blog-wrapper {
		padding: 45px 0 0;
	}
	.footer-payment-icon {
		margin-bottom: 15px;
	}
	.sin-product span {
		float: none;

	}
	.sin-product h5.pro-title {
		float: none;
	}
	.sin-product h5.pro-title a {
		width: 100%;
	}
	.sin-product.style-two .mid-wrapper span {
		text-align: center;
	}
	.color-variation {
		float: none;
	}
	.sin-product.style-two .pro-title a {
		text-align: center;
	}

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {


	.blog-wrapper {
		padding: 45px 0 0;
	}

	.main-product {
		padding: 40px 0 50px;
	}
	.banner-product {
		padding: 40px 0 20px;
	}
	.product-small {
		padding: 40px 0 0;
	}
	.footer-widget {
		margin-bottom: 40px;
	}
	.footer-widget-area {
		padding: 65px 0 0;
	}
	.footer-widget-area.style-two .footer-bottom p {
		text-align: center;
	}
	.slider-text h1 {
		font-size: 30px;
		margin: 5px 0;
		line-height: 40px;
	}
	.slider-text h4 {
		font-size: 20px;
	}
	.slider-text p {
		display: none!important;
	}
	a.btn-two {
		font-size: 14px;
		padding: 6px 24px;

	}
	a.btn-two::before {
		width: 20px;
		height: 41px;
		top: -4px;
		left: -4px;
	}
	a.btn-two::after {
		width: 20px;
		height: 41px;
		top: -4px;
		right: -4px;
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		top: 13%;
		right: 70px;
	}
	.slider-img {
		padding-top: 0;
		position: absolute;
		top: 0;
		right: 0;
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		right: 16px;
	}
	.section-heading p {
		padding: 0 2%;
		font-size:14px;
	}
	.subscribe-wrapper {
		width: 100%;
		float: none;
		margin: 13px auto 0;
		padding-bottom: 8px;
	}
	.subscribe-wrapper input {
		width: 60%;
		height: 45px;
	}
	.subscribe-wrapper button {
		width: 40%;
		border: 1px solid #a1a1a1;
		height: 45px;
		font-size: 13px;
	}
	.subscribe-text p {
		font-size: 14px;
		line-height: 24px;
	}
	.subscribe-text h6 {
		font-size: 22px;
	}
	.pro-tab-filter .pro-tab-button li {
		margin: 0 13px 13px;
		font-size: 14px;
	}
	.sin-feature {
		height: 150px;
		text-align:center;
	}

	.sin-feature .inner-sin-feature {
		padding-left: 10px;
	}
	a.btn-one {
		font-size: 15px;
		padding: 11px 30px;
	}
	.footer-menu ul li {
		margin-bottom: 5px;
	}
	.subscribe-text {
		text-align: center;
	}
	.section-heading h3 {
		font-size: 22px;
	}
	.section-heading h3::after {
		top: 30px;
	}
	.small-pro-details span {
		line-height: 22px;
	}
	.instagram-area {
		padding: 0;
	}
	.sin-banner {
		margin-bottom: 15px;
		border: 5px solid #233e47;
	}
	.sin-banner .sin-banner-con.style-two {
		padding-top: 23%;
		width: 55%;
	}
	.sin-banner-con h3 {
		font-size: 35px;
		line-height: 30px;
	}
	.sin-banner-con .banner-top {
		margin-bottom: 0px;
	}
	.sin-banner-con .banner-top::after {
		display: none;
	}
	.sin-banner-con p {
		margin-top: 0;
	}
	.sin-banner-con {
		width: 60%;
	}
	.sin-banner-con h4 {
		font-size: 20px;
	}
	.sin-banner-con h4 {
		line-height: 27px;
	}
	.sin-banner-con h4 span {
		font-size: 20px;
	}
	.sin-banner-con-right {
		top: 98px;
	}
	.sin-product {
		margin-bottom: 30px;
		min-height: 270px !important;
	}
	.padding-50 {
		padding: 30px 0;
	}
	.feature-area {
		padding: 30px 0;
	}
	.padding-120 {
		padding: 30px 0!important;
	}
	.category-area.padding-120 {
		padding: 30px 0 0 !important;
	}
	.padding-top-90{
		padding-top: 30px;
	}
	.pb-30 {
		padding-bottom: 0px;
	}
	.sin-product-s .sp-img {
		width: 25%;
	}
	.small-pro-details {
		width: 75%;
		float: none;
	}
	.single-testimonial {
		padding: 20px;
	}
	.single-testimonial .tes-img {
		float: none;
		width: 100px;
		height: 100px;
		border: 3px solid #fff;
		margin:0 auto;
	}
	.single-testimonial .tes-content {
		padding-left: 0px;
		padding-top: 30px;
	}
	.testimonial-carousel.owl-theme .owl-nav.disabled + .owl-dots {
		margin-top: 20px;
	}
	.footer-bottom {
		margin-top: 5px;
	}
	.main-product .grid-three .grid-item {
		padding: 0;
	}
	.sin-product.style-three .pro-icon::after {
		width: 77%;
	}
	.main-product .grid-three .grid-item:first-child {
		margin-top: 100px;
	}
	.main-product .grid-three .grid-item {
		margin-top: 100px;
	}
	.sin-product.style-three .mid-wrapper span {
		text-align: center;
	}
	.pro-icon ul li {
		margin: 0 12px;
	}
	.section-heading h3 {
		font-size: 18px;
	}
	.sin-product.style-three .color-variation {
		float: left;
	}
	.load-more-wrapper {
		padding: 50px 0;
	}
	.sin-product.style-three {
		margin-bottom: 0;
	}
	.footer-payment-icon ul li {
		margin-left: 5px;
	}

	.product-des-tab .nav-tabs .nav-item a:hover::after, .product-des-tab .nav-tabs .nav-item a.active::after {
		display: none;
	}
	.product-des-tab > ul {
		border-bottom: none;
	}

	.banner-wid {
		margin-bottom: 50px;
	}

/*  Sidebar area */

	.sidebar-widget.advertise-img {
		padding-bottom: 0;
	}

	.shop-area {
		padding: 50px 0px 0px;
	}
	.shop-sorting-area.row {
		margin-bottom: 50px;
	}
	.shop-sorting-area .nav-tabs {
		display: none;
	}
	.footer-instagram ul li {
		width: 23%;
	}
	/*  Shop page  */
	.shop-area {
		padding: 50px 0px 0px;
	}
	.shop-area.style-two{
		padding: 50px 0px 50px;
	}
	.product-des-tab > ul .nav-item {
		margin-right: 0px;
	}
	.product-details {
		padding-left: 15px;
		padding-top: 30px;
	}
	.product-details .color-variation {
		padding: 15px 0px;
	}
	.product-details .price {
		padding: 0px 0px 10px;
		font-size: 16px;
	}
	.product-details p {
		font-size: 15px;
		padding: 25px 0px 10px;
	}
	.product-des-tab > ul .nav-item a {
		font-size: 16px;
	}
	.product-details .pro-title a {
		font-size: 20px;
	}
	.add-tocart-wrap a.add-to-cart {
		height: 45px;
		width: 152px;
		font-size: 18px;
		float: right;
		margin-right: 5px;
		padding: 8px;
	}
	.qtybutton {
		height: 45px;
		width:32px;
	}
	.cart-plus-minus {
		height: 45px;
		width:36px;
	}
	.cart-plus-minus-button {
		margin-right: 18px;
		width:100px;
	}
	.raing-form input {
		width: 100%;
	}
	.raing-form textarea {
		width: 100%;
	}
	.cart-btn-section .coupon-code {
		margin: 15px 0px;
	}
	.cart-btn-right a {
		margin-top: 15px;
		text-align: center;
		float:none;
	}
	.cart-btn-left a {
		margin-right: 0px;
	}
	.note textarea {
		width: 100%;

	}
	.cart-subtotal a {
		height: auto;
		font-size: 13px;
	}
	.cart-btn-section {
		text-align: center;
	}
	.contact-info {
		padding: 50px 15px;
	}
	.gmap3-area {
		height: 300px;
	}
	.single-store a {
		margin-top: 10px;
	}
	.quickview-wrapper {
		width: 310px;
		height: auto;
		overflow: scroll;
	}
	.quickview .add-tocart-wrap a.add-to-cart {
		float: left;
		margin-top: 15px;
	}
	.modal.quickview-wrapper {
		width: 290px;
		height: 400px;
		overflow: scroll;
	}
	.modal.popup-1 .modal-dialog ,.modal.popup-2 .modal-dialog{
		max-width: 280px;
		max-height: 300px;
	}
	.popup-banner p {
		display:none
	}
	.popup-1 .popup-banner,.popup-2 .popup-banner {
		height: 300px;
	}
	.modal  .subscribe-wrapper input {
		height: 50px;
	}
	.modal  .subscribe-wrapper button {
		height: 50px;
	}
	.popup-banner.style-two {
		padding-top: 50px;
		padding-right: 0;
	}
	.popup-banner.style-two .popup-subscribe .subscribe-wrapper {
		width: 235px;
	}
	.popup-banner.style-two .subscribe-wrapper input {
		height: 40px;
	}
	.popup-banner.style-two .subscribe-wrapper button {
		height: 40px;
		width: 100%;
	}
	.popup-banner.style-two h3 {
		font-size: 20px;
	}
	.pb-info {
		width: 250px;
		bottom: 25px;
	}
	.pb-info::after {
		width: 270px;
	}
	.pro-tab-filter.style-two .pro-tab-button {
		text-align: center;
		padding:10px 0;
	}
	.padding-150 {
		padding: 50px 0;
	}
	.product-social ul li {
		width: 28px;
	}
	.next-prev-btn {
		margin-top: 0px;
	}
	.children-comment {
		padding-left: 20px;
	}

	.slider-text p {
		display: none !important;
	}

	.slider-wrapper {
		padding-top: 54px;
	}
	.slider-text h1 {
		display: none !important;
	}
	.sin-category .cat-name {
		margin-top: -40px;
	}
	.sin-category .cat-name a h5 {
		line-height: 21px;
		font-size: 16px;
	}
	.slider-text.style-two h1 {
		font-size: 14px;
		padding-bottom: 0px;
		display: block !important;
		line-height: 4px;
		margin-top: 16px;
	}
	.sin-category .cat-name {
		margin-top: -28px;
	}
	.sin-category .cat-name a {
		font-size: 11px;
		padding: 11px 25px;
	}
	.sin-category .cat-name a p {
		line-height: 20px;
	}
	.section-heading h3::after {
		margin-left: -65px;
		width: 130px;
	}
	.padding-top-120 {
		padding-top: 30px;
	}

	.slider-text h4 {
		font-size: 13px;
	}
	.slider-text a.btn-two::before ,.slider-text a.btn-two::after{
		display: none;
	}
	.login-now span {
		width: 100%;
		display: block;
		font-size: 22px;
		margin-bottom: 10px;
	}

	.cart-table .tables tbody tr td a {
		color: #525353;
	}

	.cart-table .tables tbody tr td .product-title a {
		font-size: 14px;
	}

	.info-wrap .sin-aditional-info .first {
		width: 100%;
	}
	.info-wrap .sin-aditional-info .first {
		border-right: none;
		border-left: none;
	}

	.subscribe-area.style-two .subscribe-text h6 {
		margin-bottom: 30px;
	}

	.subscribe-area.style-two {
		padding: 60px 0px 80px;
	}

	.footer-payment-icon ul li {
		margin-bottom: 12px;
		width: 20%;
	}

	.cart-title > p a {
		font-size: 13px;
	}
	.cart-price > p {
		font-size: 14px;
	}

	.cart-sub-total > p {
		font-size: 14px;
		padding: 0px 10px 10px;
		margin-bottom: 0;
	}
	.cart-sub-total p span {
		font-size: 14px;
	}

	.cart-checkout {
		margin-left: 14px;
	}

	.main-product .sin-product h5.pro-title a {
		font-size: 14px;
		line-height: 18px;
		text-align: center;
	}

	.main-product .sin-product span {
		float: none;
		font-size: 14px;
		text-align: center;
	}
	.main-product .sin-product h5.pro-title {
		font-weight: 400;
		float: none;
		margin-bottom: 0;
	}
	.main-product .sin-product .pro-icon {
		display: none;
	}
	.main-product .sin-product .color-variation ul li {
		font-size: 10px;
	}
	/* .main-product .sin-product.style-two{
		min-height: 300px!important;
	} */
	.main-product .sin-product.style-two .mid-wrapper span {
		font-size: 14px;
		float: none;
	}
	.main-product .sin-product .color-variation {
		float: none;
	}
	.main-product .sin-product.style-two .add-to-cart {
		display: none;
	}
	.main-product .sin-product h5.pro-title {
		text-align: center;
		width:100%;
	}
	.cart-checkout a {
		font-size: 13px;
		font-weight:400;
	}
	.cart-share a {
		font-weight: 400;
		font-size: 13px;
	}
	.sin-product.style-two .new-tag {
		display: none;
	}
	.sin-product.style-two .pro-title a {
		width: 136px;
	}
	.backtotop {
		border: none;
	}
	.backtotop i {
		height: 31px;
		width: 31px;
		line-height: 31px;
	}
	.prod-banner-two {
		margin-bottom: 30px;
		margin-top:0;
	}
	.main-product .pro-tab-filter .pro-tab-button {
		padding: 20px 0px 50px;
	}
	.contact-area {
		padding: 30px 0 40px;
	}
	/* Cart Page table */

	table, thead, tbody, th, td, tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr {
		border: 1px solid #ccc;
		border-bottom:10px solid #efefef;
	}

	td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding: 10px 0px;
		padding-left: 0px;
		padding-left: 0;
		text-align: center;
	}

	td:before {
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		display: none;
	}
	.quantity input {
		width: 70px;
		padding-left: 12px;
	}

	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Remove"; }
	td:nth-of-type(2):before { content: "Product Image"; }
	td:nth-of-type(3):before { content: "Product Name"; }
	td:nth-of-type(4):before { content: "Quantity"; }
	td:nth-of-type(5):before { content: "unit price"; }
	td:nth-of-type(6):before { content: "Total"; }

	.list-pro-det {
		margin-bottom: 50px;
	}

}

@media (max-width: 767px) {
	.sin-product.style-one h5.pro-title {
		width: 100%;
		text-align: center;
	}
	.sin-product.style-two .mid-wrapper p {
		text-align: center;
	}
	.sin-product.style-two .mid-wrapper p {
		font-weight: 400;
		font-size: 14px;
	}
	.sin-blog .title {
		font-size: 16px;
	}
	.sin-product.style-three .mid-wrapper p {
	text-align: center;
	}
	.main-product.bg-one::before {
	height: 220px;
	}
}
@media (max-width: 991px) {
	.accordion-wrapper {
		display: block;
	}
	.mobile-header {
		z-index: 998;
		width: 100%;
		left: 0;
		background: #fff;
		height: 55px;
	}
	.logo {
		padding-top: 13px;
		text-align: center;
	}
	.top-cart {
		float: right;
		padding-top: 14px;
		padding-right: 7px;
	}
	.top-cart > a {
		color: #3f3f3f;
		font-size: 15px;
	}
	.mobile-header {
		display: block;
		position: fixed;
	}
	.header-area{display:none}
	.footer-widget .logo {
		text-align: left;
	}
	.footer-payment-icon ul {
		float: none;
		margin-top: 20px;
	}
	.footer-payment-icon {
		text-align: center;
		margin-bottom: 10px;
	}
	.footer-bottom {
		text-align: center;
	}
	.home-version-3 {
		padding: 0px 15px 15px;

	}
	.sin-category {
		margin-bottom: 30px;
	}
	.breadcrumb-area {
		padding: 75px 0px 10px;
	}

	.slider-text.mob-align-left{
		text-align: left!important;
	}


}




@media (min-width: 480px) and (max-width: 575px) {


	.slider-text h4 {
		font-size: 19px;
	}
	.slider-text.style-two,.slider-text {
		text-align: right;
	}
	.slider-text {
		padding-right: 65px;
	}
	.slider-text.style-two h1 {
		font-size: 19px;
	}

}

@media (min-width: 320px) and (max-width: 479px) {

	.slider-text, .slider-text.style-two{
		text-align: right;
		padding-right: 20px;
	}


}
