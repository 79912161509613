.sin-banner {
	position: relative;
	text-transform: uppercase;
	overflow: hidden;
	border: 10px solid $forth_color;
	@include transition(all .3s);
	img{
		@include transition(all .3s);
	}


}

.sin-banner .sin-banner-inner-wrap {
	top: 50%;
	position: absolute;
	width: 100%;
	transform: translateY(-50%);
}

@media (min-width: 1200px){



}

.sin-banner-con
 	{
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 50%;
	background: rgba(255, 255, 255, 0.93);

	h4 {
		line-height: 37px;
		margin: 0;
		text-transform: uppercase;

			@include WorkSans-bold;
			color: $forth_color;
			font-size: 28px;
			span {
				font-weight: 400;
				display: inline;
				font-size: 28px;
			}

	}
	h3 {
		@include WorkSans-bold;
		color: $main_color;
		font-size: 45px;
	}
	p {
		@include WorkSans-medium;
		font-size: 20px;
		color: $forth_color;
		margin-top:40px;

	}
	span {
		@include WorkSans-medium;
		font-size: 20px;
		color: $forth_color;
		display: block;
		line-height:24px;
	}
	.banner-top{
		position: relative;
		margin-bottom: 24px;
		&:after{
			position: absolute;
			left: 50%;
			bottom: -12px;
			height: 1px;
			width: 220px;
			background: #233e47;
			content: '';
			margin-left: -110px;
		}
	}




}


.sin-banner.borders:hover {
	border-color:#d19e66;
}

.sin-banner-con{
	top: 0px;
	left: 0;
	height: auto;
	bottom: 0;
	width: 50%;
	h4 {
		a {
			font-size: 20px;
		}
	}
	span {
		font-size: 18px;
	}


}



.sin-banner-con-right{
		background: #d19e66;
		color: #fff;
		padding: 4px 47px;
		text-align: center;
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		position: absolute;
		top: 212px;
		right: 0;
		margin-right: -98px;
	p {
		font-size: 20px;
		@include WorkSans-bold;
		color: #fff;
		margin: 0 ;
	}
	span {
		font-size: 18px;
		@include WorkSans-semi-bold;
		color:#fff;


	}
}

.sin-banner:hover img {
	transform: scale(1.1);
	@include transition(all .3s);

}
