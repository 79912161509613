.subscribe-text{
	h6 {
		font-size:22px;
		@include WorkSans-bold;
		color: $main_color;
		text-transform: uppercase;
		span {
			color: $second_color;
		}
	}
	p {
		font-size: 16px;
		color: $fifth_color;
		line-height: 28px;
	}
}


.subscribe-wrapper button {
    width: 30%;
    border: 1px solid #a1a1a1;
    height: 50px;
    border-radius: 0px 5px 5px 0px;
    margin-left: -5px;
    background: #464646;
    color: #fff;
    font-size: 20px;
    border-left: none;
    cursor: pointer;
}
.subscribe-wrapper button:hover {
	color: #d19e66;
}

.subscribe-wrapper input {
    width: 70%;
    border: 1px solid #a1a1a1;
    border-radius: 5px 0px 0px 5px;
    height: 50px;
    border-right: none;
    position: relative;
    float: left;
    position: relative;
    padding-left: 20px;
}

.subscribe-wrapper {
	width: 660px;
	float: right;
	margin-top: 25px;
}
.subscribe-area{
	padding: 60px 0px;
	border: 1px dashed #cbcbcb;
	display: block;
	border-left:0;
	border-right:0;
}

/* ------ Subscribe style two ----- */

.subscribe-area.style-two {
	border: 0px dashed #cbcbcb;
	background: #e7f6f7;
	@include box-shadow(1.236px, 3.804px, 8px, 0px, rgba(102, 101, 101, 0.09));
	padding:60px 0px;
	.subscribe-wrapper {
		margin-top: 0px;
	}
	.subscribe-wrapper input {
		border: 0px solid #a1a1a1;
		border-radius: 0px;
		height: 50px;
	}
	.subscribe-wrapper button {
		border: 0px solid #a1a1a1;
		height: 50px;
		border-radius: 0px;
		background: #fff;
		color: $main_color;
		font-weight: 500;
		position:relative;
		&:after{
			content: '';
			position: absolute;
			width: 2px;
			height: 16px;
			background: #eeeded;
			left: 14px;
			top: 50%;
			margin-top: -8px;
		}
	}
	.subscribe-text {
		margin-top: 13px;
		h6{
			color:#4d4d4d;
		}
	}


}

.subscribe-area.style-two .subscribe-wrapper button:hover {
	color: #4d4d4d;
}

.BG {
  background-color: rgb(255, 255, 255);
  opacity: 0.969;

  position: absolute;
  left: 829px;
  top: 144px;
  width: 785px;
  height: 338px;
  z-index: 501;
}

