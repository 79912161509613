
.rating {
	ul {
		li {
			display: inline-block;
			a{
				color: #5d5d5c;
				font-size:11px;

			}
		}
	}
}

.mid-wrapper.style-two{
	.pro-title{
			display:block;
			width:100%;
			margin-bottom: 3px;
	}
	.rating{
			display:block;
	}
	span{
		display: block;
		width: 100%;
		margin-top: 5px;
		font-size: 20px;
	}
}

.sin-product.style-two.small .icon-wrapper {
	bottom: 123px;
}