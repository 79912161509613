.blog-wrapper .pro-tab-filter .pro-tab-button {
	padding: 20px 0px 50px;
}
.blog-wrapper{
	padding:45px 0 70px;
}

.sin-blog{
	text-align:left;
	margin-bottom: 50px;
	.title {
		font-size: 19px;
		color:#464646;
		text-transform: uppercase;
		@include WorkSans-semi-bold;
	}
}

.blog-content {
	width: 92%;
	border: 1px solid #ddd;
	padding: 40px 20px 45px;
	margin: 0 auto;
	margin-top: -80px;
	background: #fff;
	position: relative;
}
.blog-meta {
	position: absolute;
	background: #d19e66;
	padding: 6px 16px;
	right: 8px;
	top: -8px;
	a{
		color: #fff;
	}
}
.blog-details{

	p {
		font-weight: 400;
		line-height: 28px;
		color: #636363;
	}
	> ul{
		list-style-type: inherit;
		padding:25px 0;
		padding-left: 25px;
		padding-top: 15px;
		li{
			color:#3f3f3f;
			padding-bottom:10px;

		}
	}

}

.blog-details {
	overflow: hidden;
}

.blog-details a {
	color: $main_color;
	text-transform: capitalize;
}


.blog-details a:hover{
	text-decoration: underline;
}

.sin-blog.single-page{
	h5{
		font-size: 19px;
		color: #464646;
		text-transform: uppercase;
		font-weight: 600;
	}
	h6{
		font-size: 17px;
		font-weight: 400;
		margin-top: 26px;
		color: $title_color;
	}
	.blog-content {
		width: 100%;
		border: none;
		padding-left: 0;
	}
	.blog-details{
		a{
			color: #464646;
			font-size: 16px;
			text-decoration: none;
			margin-top: 10px;
			font-weight:600;
		}
		p{
			float: left;
		}
	}
}

.sin-blog.single-page .blog-details a:hover{
	color: $main_color;
}

.blog-details .social {
	float: right;
	margin-top: 10px;
	li{
		margin-right: 16px;
	}
}

.next-prev-btn{
	text-align: center;
	margin-top: 50px;
	a{
		color: #fff;
		padding: 8px 19px;
		background: #d19e66;
		margin-right: 8px;
		font-size: 24px;
	}
}
.comments-list .heading {
	text-transform: uppercase;
	font-size: 19px;
	padding-bottom: 40px;
}
.commentlists-div ol, .commentlists-div ul {
	list-style: outside none none;
}
.commentlists {
	border-bottom: 1px solid #e7e6e6;
	margin-bottom: 25px;
	padding-bottom: 25px;
	padding-left: 0px;
}
.commentlists div.avatar {
	float: left;
	margin-right: 17px;
	overflow: hidden;
	display: block;
	border-radius: 50px;
}
.commentlists .the-comment .comment-box {
	margin-left: 70px;
	overflow: hidden
}
.children-comment {
	padding-left: 70px;
}
.commentlists .the-comment {
	border-top: 1px solid #e7e6e6;
	margin-top: 25px;
	padding-top: 25px;
}
.com-name {
	color: #7b7b7b;
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 0;
}
.comment-text {
	color: #636363;
	font-weight: 400;
}
.comment-author.meta > p {
	color: #7b7b7b;
	font-size: 13px;
}
.sin-post .form-control {background-color: #fff;}
.comment-form .form-control {border: 1px solid #f1f1f1;}
.btn.btn-primary.submit {border: 0 solid;}
.blog-meta span {margin-right: 6px;}
.btn.btn-primary.continue-shopping.pull-left {
	background: #fff none repeat scroll 0 0;
	border: 1px solid #ddd;
	color: #444;
}
.btn.btn-primary.continue-shopping.pull-left:hover {
	background: #ddd none repeat scroll 0 0;
	color: #000;
}
.form-control:focus {
	box-shadow: none;
}
.comment-reply-link {
	color: #7b7b7b;
	margin-left: 14px;
}
.comment-reply-link:hover{
	color: $main_color;
}

.form-submit .submit {
	background: #d19e66;
	color: #fff;
	font-size: 15px;
	padding: 12px 20px;
	width: 150px;
	font-weight: 500;
	cursor: pointer;
	border: none;
	@include transition(all .3s)
}

.form-submit .submit:hover{
	background: $second_color;
}

/*--------------------------------------------------------------
  ##  Blog sidebar css
  --------------------------------------------------------------*/

  .blog-widget{
  	padding-bottom: 50px;
  	.widget-title{
  		padding-bottom: 15px;
  		font-size: 20px;
  		font-weight: 400;
  		text-transform: uppercase;
  		letter-spacing: 2px;
  	}
  	.widget-post{
  		overflow: hidden;
  		margin-bottom: 30px;
  		.widget-post-img{
  			float: left;
  			width: 44%;
  			padding-right: 16px;

  		}
  		.widget-post-content{
  			h6{
  				a{
  					color: $title_color;
  					font-size: 16px;
  					font-weight: 500;
  					&:hover{
  						color: $main_color
  					}
  				}

  			}
  			p{
  				font-size: 14px;
  				background: #edeef0;
  				color: #3f3f3f;
  				overflow: hidden;
  				display: inline-block;
  				padding: 8px;
  				margin-top: 6px;

  			}
  		}
  	}
  }

  .wid-category{
  	li{
  		padding-bottom: 20px;
  		a{
  			position: relative;
  			color: $seven_color;
  			padding-left: 20px;
  			text-transform: capitalize;
  			&:hover{
  						color: $main_color
  					}

  		}
  		:after{
  			content: '\f105';
  			position: absolute;
  			top: 0;
  			left: 0;
  			font-family: 'Font Awesome 5 Free';
  			font-weight: 900;
  			color: $seven_color;

  		}
  	}
  }

  .widget-tag{
  	li{
  		display: inline;
  		a{
  			color: $seven_color;
  			display: inline-block;
  			padding: 8px 15px;
  			border: 1px solid $seven_color;
  			border-radius: 30px;
  			font-size: 14px;
  			margin-bottom: 12px;
  			margin-right: 6px;
  		}
  	}
  }

  .widget-tag li a:hover{
  	background: #d19e66;
	color: #fff;
	border-color: #d19e66;
  }

  .blog-details{
  	blockquote{
  		margin:25px 5px;
  		q{
  			font-style: italic;
  			font-size: 16px;
  			line-height: 30px;
  			position: relative;
  			font-weight: 400;
  			margin:30px 20px;
  		}
  	}
  }

  .comment-form {
	padding-top: 50px;
}