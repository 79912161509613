
.footer-widget-area{
	padding:120px 0 0;
	background: #080606;
}

.footer-widget-area .footer-bottom p {
	color: #fff;
	text-align: center;
}
.footer-widget-area.style-two .footer-bottom p {
	color: #3f3f3f;
	text-align: left;
}
.footer-widget{

	.logo{
		padding:5px 0 33px;
		a{

		}
	}
	p{
		font-size: 16px;
		color: #dcdbdb;
		line-height: 28px;
		@include WorkSans-normal;
		padding-right: 60px;
		padding-bottom: 10px;
	}
}

.social{
	ul {
		li {
			display:inline-block;
			margin-right: 5px;
		}
		a {
			color: #fff;
			font-size:16px;

		}
	}
}
.footer-menu{
	ul {
		li {
			margin-bottom: 14px;
			padding-bottom: 5px;

			a {
				font-size:16px;
				color:#dbdbdb;
			}
		}
	}
}

.footer-widget{
	h3{
		font-size:18px;
		color:#fff;
		@include WorkSans-medium;
		padding-bottom: 28px;
		text-transform:uppercase;
	}
}
.payment-link{
	li{
		display: inline-block;
		a{

		}
	}
}

.footer-subscribe-wrapper{
	input{
		width: 100%;
		border: 1px solid #ddd;
		height: 45px;
		margin-bottom: 15px;
		padding-left: 21px;
	}
	button{
		width: 100%;
		border: 1px solid #ddd;
		height: 45px;
		background: #ece7e7;
		font-size: 20px;
		cursor: pointer;
	}

}

.footer-menu ul li a:hover {
	color: $main_color;
}

.footer-widget-area .footer-bottom p a {
	color: $main_color;
}

/*--------------------------------------------------------------
  ##   Footer Style two start
  --------------------------------------------------------------*/


.footer-widget.style-two{
	.time-table{
		p{
			color: $second_color;
			@include WorkSans-semi-bold;
		}
		span{
			color: $second_color;
			@include WorkSans-normal;
			padding-bottom:8px;
			display:block;
			text-transform:uppercase;
		}
	}
	h3{
		color:$second_color;
	}
	.footer-menu{
		ul{
			li{
				border-bottom:none;
				margin-bottom:10px;
				padding-bottom:0px;
				a{color: $second_color;}
			}
		}
	}
	p {
		color: $second_color;
	}
}
.footer-widget-area.style-two{
	background:#f4f4f4;
	padding-bottom:0px;
}
.footer-instagram{
	ul{
		li{
			display: inline-block;
			margin: 5px;
			width: 29%;
			border: 5px solid #fff;
			a{

			}
		}
	}
}
.footer-bottom{
	border-top: 1px solid #c6c5c5;
	padding:40px 0;
	margin-top:75px;
	p{
		color:$second_color;
	}
}
.footer-payment-icon{
	ul{
		float:right;
		li{
			margin-left: 10px;
			display:inline-block;
			a{

			}
		}
	}
}

.footer-widget.style-two .footer-menu ul li a:hover{
	color: $main_color;
}

/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/


.backtotop {
	position: fixed;
	bottom: 25px;
	right: 25px;
	border: 2px solid #fff;
	cursor: pointer;
	border-radius: 50%;
	z-index: 9999;
	-webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
	-moz-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
	box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
}

.backtotop i {
	height: 42px;
	width: 42px;
	line-height: 42px;
	font-size: 20px;
	background: #fff;
	margin: 2px;
	color: $main_color;
	text-align: center;
	border-radius: 50%;
}

.footer-widget-area .footer-bottom p span {
	color: $main_color;
}