.breadcrumb-area {
	background: #f6f6f6;
	padding: 170px 0px 10px;
}


.bc-inner{
	p{
		font-size: 16px;
		color: $main_color;
		@include WorkSans-medium;
		text-transform: uppercase;
		a{
			color: $second_color;
			@include WorkSans-medium;
		}
	}
}

.bc-inner p a:hover {
	color: $main_color;
}