
.section-heading {
	text-align: center;
	h3 {
		font-size: 26px;
		@include WorkSans-bold;
		color: $main_color;
		text-transform:uppercase;
		padding-bottom: 50px;
		position:relative;
		line-height:26px;

		span{
			color : $second_color;
		}
		&:after{
			content: '';
			position: absolute;
			left: 50%;
			top: 0;
			width: 250px;
			height: 30px;
			background-image: url(../../media/images/icon/line.png);
			background-repeat: no-repeat;
			background-position: center;
			top: 40px;
			margin-left: -125px;

		}
	}
	p {
		font-size: 16px;
		color: $fifth_color;
		padding:0 25%;
		padding-bottom:20px;
	}
}

.main-product {
	padding: 110px 0 60px;
}
.pro-tab-filter{
	text-align: center;
	.pro-tab-button{
		li{
			@include WorkSans-medium;
			display: inline-block;
			font-size:17px;
			color: $fifth_color;
			margin: 0 30px;
			cursor:pointer;
			&:hover{color:$main_color}
			text-transform:uppercase;

		}
		li.active{color:$main_color}
	}
}

.sin-product {
	margin-bottom: 50px;
	text-align: center;
	position: relative;
	cursor: pointer;
	h5.pro-title {

		@include WorkSans-normal;
		float: left;
		a{
			color: $title_color;
			font-size: 18px;
			&:hover{ color : $main_color;}
		}
	}
	span{
		color: $main_color;
		@include WorkSans-normal;
		float: right;
		font-size: 18px;
	}
	.pro-img{
		position:relative;
		&:after{
			position:absolute;
			height:100%;
			background:#f2f4f4;
			opacity:0;
			content:'';
			top:0;
			left:50%;
			right:50%;
			z-index:0;
			@include transition(all 0.3s ease-in-out);
		}
	}
}

.banner-product .sin-product {
	margin-bottom: 35px;
}
.banner-product {
	padding: 115px 0 75px;
}
.sin-product:hover .pro-img:after{
	opacity: .70;
	right:0;
	left: 0;
	@include transition(all 0.5s ease);
}
.mid-wrapper {
	overflow: hidden;
	padding: 18px 0 0;
	z-index: 99;
	position: relative;
}

.pro-icon {
	width: 100%;
	position: absolute;
	top: 40%;
	left: 0;
	z-index: 999;
	visibility: hidden;
	opacity: 0;
	@include transition(all 0.3s ease);
	ul {
		li {
			display: inline-block;
			margin: 0 30px;
			position: relative;
			@include transition(all 0.3s ease);
			a {
			color: #fff;
			font-size: 16px;
			text-align: center;
			display: block;
			padding: 7px;
			}
			&:after{
				content: '';
				position: absolute;
				width: 35px;
				height: 35px;
				background: #3a3a3a;
				left: -2px;
				z-index: -1;
				top: 0px;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				border-radius: 5px;
				@include box-shadow(2.5px, 4.33px, 21px, 0px , rgba(90, 89, 89, 0.3))
			}

		}

	}

}

.sin-product:hover .pro-icon{opacity:1;visibility:visible;}

.sin-product:hover .pro-icon ul li{margin: 0 12px;@include transition(all 0.4s ease);}

.main-product .pro-tab-filter .pro-tab-button {
	padding: 20px 0px 50px;
}

.main-product .sin-product.style-three h5.pro-title a {
	color: #525353;
	font-size: 18px;
	font-weight:600;
}

.shop-four-grid .sin-product.style-two .pro-title a {
	width: 183px;
}

.sin-product.style-one h5.pro-title {
	width: 75%;
	text-align: left;
}
.pro-icon ul li a:hover {
	color: #d19e66;
}