.single-testimonial{
		padding: 50px 35px 50px 50px;
		color: $fifth_color;
		font-size: 16px;
		background: #fff;
		border-radius:30px 0 30px 0;
		box-shadow: 0px 0px 9px 0px rgba(126, 124, 124, 0.1);
	.tes-img{
		float: left;
		width: 122px;
		height: 122px;
		border: 6px solid #fff;
		border-radius: 100%;
		overflow: hidden;
		box-shadow: 0px 0px 27px 0px rgba(126, 124, 124, 0.17);
		img{
			border-radius: 100%;
		}

	}
	.tes-content{
		overflow: hidden;
		padding-left: 32px;
		padding-top: 16px;
		p{

			@include WorkSans-medium;
			font-style: italic;
			position: relative;
			&:after{
				position: absolute;
				content: '\f10d';
				width: 10px;
				height: 10px;
				top: -22px;
				left: 0;
				font-family: 'Font Awesome 5 Free';
				font-size: 18px;
				color: #949494;
				font-weight:900;
			}

		}
		span{
			@include WorkSans-semi-bold;
			text-transform: uppercase;
			position:relative;
			padding-left:50px;
			&:after{
				position: absolute;
				content: '';
				width: 30px;
				height: 2px;
				top: 10px;
				left: 0;
				background: $main_color;

			}
		}
	}
}

.testimonial-carousel.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 0 20px;
}
.testimonial-carousel.owl-theme .owl-dots .owl-dot.active span {
	background: #d19e66;
	width: 18px;
	height: 10px;
}
.testimonial-carousel.owl-theme .owl-dots .owl-dot span {
	width: 16px;
	margin: 0px 4px;
	background: #3f3f3f;
	display: inline-block;
	border-radius: 0px;
	width: 10px;
	height: 10px;
}
.section-heading.no-paragraph {
	padding-bottom: 40px;
}
.testimonial-carousel.owl-theme .owl-nav.disabled + .owl-dots {
	margin-top: 55px;
}

