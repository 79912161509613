.contact-area {
	padding: 50px 0 120px;
}

.contact-form{
	text-align:center;
	input{
		width: 100%;
		height: 56px;
		background:#f2f1f1;
		border: none;
		margin-bottom: 30px;
		padding: 0px 20px;


	}
	textarea{
		width:100%;
		height: 165px;
		background:#f2f1f1;
		border:none;
		margin-bottom: 30px;
		padding: 10px 20px;
	}
	input[type="submit"]{
		background: #d19e66;
		color: #fff;
		font-size: 15px;
		padding: 0px 20px;
		width: 150px;
		font-weight: 700;
		cursor: pointer;
		margin-bottom:0;
		&:hover{
			background:$second_color

		}
	}
}
.gmap3-area {
	height:600px;
}

.google-map{
	position: relative;
}

.contact-info{
	text-align: center;
	background-image: url(../../media/images/banner/con_bg.jpg);
	width: 100%;
	position: relative;
	top: 0;
	height: 100%;
	font-family: 'Work Sans', sans-serif;
	font-weight: 700;
	background-repeat: no-repeat;
	right: 0;
	z-index: 0;
	padding-top: 200px;
	font-family: 'Work Sans', sans-serif;
	font-weight: 700;
	background-size: cover;

	@include WorkSans-bold;
	h5{
		font-size:30px;
		color:$main_color;
		@include WorkSans-bold;
		text-transform:uppercase;
	}
	> span{
		text-transform:uppercase;
		color:#787878;
		font-size:16px;
		font-weight:400;
		margin-bottom: 35px;
		display: block;
	}
	p{
		font-size:16px;
		color:#464646;
		font-weight:500;
		margin-bottom: 8px;
		span{
			color: $main_color;
		}
	}
}

.single-store{
	text-align:center;
	a{
		font-size:20px;
		@include WorkSans-bold;
		color: $title_color;
		text-transform:uppercase;
		margin-top: 35px;
		&:hover{
			color:$main_color;
		}
	}
	p{
		color: $fifth_color;
		font-size:16px;
		text-transform:uppercase;
		margin-top: 5px;
	}
}



/*--------------------------------------------------------------
  ##  Login page css
  --------------------------------------------------------------*/

.login-form

.contact-form.login-form input {
	height: 50px;
	margin-bottom: 25px;;
}

.login-now {
	text-align: center;
	padding: 50px 0;
	background: #f2f1f1;
}
.login-now span {
	margin-right: 50px;
	color: #3f3f3f;
	font-size: 25px;
}
.contact-form.login-form input[type="submit"] {
	width: 100%;
}