.pro-tab-filter.style-two{
	text-align: left;
	.pro-tab-button{
		padding: 30px 0px;
		margin-bottom: 20px;
		text-align:center;
		li:first-child{
			margin-left: 0px;
		}
	}
}

.sin-product.style-two{
	.pro-title{
		margin-bottom: 3px;

		a{
		white-space: nowrap;
		width: 240px;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		text-align:left;
		}

	}
	.pro-icon{
		top: 0;
		ul{
			li{
				a{
					color:#585858;
					&:hover{color: $main_color;}
					font-size:23px;

				}
				&:after{
					display:none
				}
				&:before{
					background: url(../../media/images/icon/li-border.png);
					background-size: auto;
					content: '';
					width: 5px;
					height: 47px;
					position: absolute;
					top: 10px;
					right: -18px;
					background-repeat: no-repeat;
				}
				&:last-child:before{
					display:none;
				}
			}
		}
	}
	.add-to-cart{
		position: absolute;
		bottom: 0px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 100%;
		z-index: 99999;
		visibility: hidden;
		opacity: 0;
		@include transition(all .3s);
		a{
			color: #d19e66;
			font-size: 15px;
			padding: 10px 25px;
			background: #fff;
			border-radius: 50px;
			text-transform: uppercase;
			-webkit-box-shadow: -0.908px 1.782px 9px 0px rgba(126, 124, 124, 0.3);
			box-shadow: -0.908px 1.782px 9px 0px rgba(126, 124, 124, 0.3);
			font-family: 'Work Sans', sans-serif;
			font-weight: 600;
		}
	}
	&:hover .add-to-cart {
		visibility:visible;
		opacity:1;

	}
	.new-tag {
		color: #fff;
		background: #66a2f4;
		padding: 2px 10px;
		border-radius: 23px;
		font-size: 16px;
		position: absolute;
		top: 25px;
		left: 20px;
	}
	.mid-wrapper{
		> span{
			color: #7b7b7b;
			@include WorkSans-medium;
			float: left;
			font-size: 16px;
			display: inline;
			width: 100%;
			text-align: left;

		}
		p{
			color: #7b7b7b;
			@include WorkSans-medium;
			float: left;
			font-size: 16px;
			display: inline;
			width: 100%;
			text-align: left;
			span{
			color: $main_color;
			@include WorkSans-medium;
			float: none;
			font-size: 16px;

			}

		}
	}
	.icon-wrapper {
		position: absolute;
		width: 100%;
		bottom: 170px;
		height: 115px;
	}
}

.color-variation{
	float:right;

	ul{

		li{
			display:inline-block;
			&:nth-child(1){color:#cdcd16}
			&:nth-child(2){color:#45ce09}
			&:nth-child(3){color:#cd163d}
			&:nth-child(4){color:#4c4c4c}
		}
	}
}

/*--------------------------------------------------------------
  ##  Product Three
  --------------------------------------------------------------*/
  .sin-product.style-three  {
  	position:relative;
  	padding:0 20px 30px;
  	.pro-title{
  		margin-bottom: 3px;
  	}
  	.mid-wrapper span {
  		color: #7b7b7b;
  		font-family: 'Work Sans', sans-serif;
  		font-weight: 400;
  		float: left;
  		font-size: 18px;
  		display: inline;
  		width: 100%;
  		text-align: left;
  	}

  }
  .main-product{
  	.grid-three{
  		.grid-item {
  			padding: 0 70px;
  		}
  	}

  }
  .main-product .grid-three .grid-item{margin-top:115px;}
  .main-product .grid-three .grid-item:first-child{margin-top:330px;}
  .sin-product.style-three:after{
  	content:'';
  	background:#fff;
  	position:absolute;
  	bottom:0;
  	left:0;
  	width:100%;
  	height:475px;

  }
  .pro-img-three {
  	position: relative;
  	z-index: 99;
  }
/*   .sin-product.style-three .pro-icon::after {
	position: absolute;
	content: '';
	width: 50%;
	height: 107px;
	left: 50%;
	top: -32px;
	z-index: -2;
	border: 5px solid #fff;
	opacity: 0.7;
	background: #fff;
	transform: translateX(-50%);
	border: 1px solid #000;
} */
  .sin-product.style-three .pro-img-three{
  	.img-hover{
  		visibility:hidden;
  		opacity:0;
  		@include transition(all .3s);
  	}
  	.img-show{
  		visibility:visible;
  		opacity:1;
  		@include transition(all .3s);
  		position:absolute;
  		width:100%;
  		left:0;
  	}
  }
  .sin-product.style-three:hover{
  	.pro-img-three{
  		.img-hover{
  			visibility:visible;
  			opacity:1;
  			@include transition(all .3s);
  			transform: rotateY(180deg) ;
  			-webkit-transform: rotateY(180deg);

  		}
  		.img-show{
  			visibility:hidden;
  			opacity:0;
  			@include transition(all .3s);
  			transform: rotateY(180deg) ;
  			-webkit-transform: rotateY(180deg);
  		}
  	}
  }

  .main-product.bg-one {
  	position: relative;
  }


  .main-product.bg-one::before {
  	content: '';
  	position: absolute;
  	width: 100%;
  	height: 300px;
  	background: #f0f0f0;
  	top: 0;
  	left: 0;
  	z-index: 0;
  }

  .pro-icon.style-three ul li {
	display: block;
	margin: 0 30px;
	position: relative;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	width: 50px;
	height: 56px;
}
.pro-icon.style-three {
	width: 75px;
	position: absolute;
	top: 40%;
	left: 7px;
	z-index: 999;
	visibility: hidden;
	opacity: 0;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.pro-icon.style-three ul li::after {
	content: '';
	position: absolute;
	width: 35px;
	height: 35px;
	background: #fff;
	left: 8px;
	z-index: -1;
	top: 0px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	border-radius: 5px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #dbd8d8;
}
.pro-icon.style-three ul li a {
	color: $main_color;
}
.sin-product.style-three .mid-wrapper p span {
	color: #d19e66;
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
	float: none;
	font-size: 18px;
}
.sin-product.style-three .mid-wrapper p {
	float: left;
	font-size: 18px;
	display: inline;
	width: 100%;
	text-align: left;
	font-weight: 400;
}








