
@media (min-width: 1200px) {

	.slider-1 {
		padding-top: 100px;
	}

}


.slider-wrapper .item {
	background-size: cover;
}

.slider-content {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	a.btn-two {
		margin-bottom: 6px;
	}
}

.slider-text.style-two.mob-align-left {
	padding-right: 0;
}

.slider-text{
	h1{
		font-size: 80px;
		color: $main_color;
		@include Roboto-bold;
		margin: 10px 0;

	}
	h4{
		font-size: 26px;
		color: $main_color;
		@include Roboto-bold;
		margin: 0;
	}
	span{
		color:#1d1b1b;
	}
	p{
		font-size: 18px;
		color:$third_color;
		@include WorkSans-normal;
		margin-bottom: 30px;
	}


}
a.btn-one{
	font-size: 20px;
	color: #fff;
	background: #3f3f3f;
	padding: 13px 44px;
	border-radius: 29px;
	@include Roboto-bold;
	margin-top: 10px;
	&:hover{
		background: $main_color;
		color:$second_color;
	}
}

.slider-text {
	padding-right: 100px;

}
.background-1{
	background-image: url(../../media/images/banner/f1.jpg);
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}
.background-2{
	background-image: url(../../media/images/banner/f2.jpg);
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}
	.background-3{
		background-image: url(../../media/images/banner/f3.jpg);
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
	}
.slider-img {
	padding-top: 60px;
	text-align: center !important;
}
.owl-carousel .owl-item .slider-img img{
	max-width: 100%;
	width:auto;
}
.slider-wrapper .owl-theme .owl-dots .owl-dot.active span {
	background: $main_color;
	width: 10px;
	height: 32px;
}

.slider-wrapper .owl-theme .owl-dots .owl-dot span {
	width: 16px;
	margin: 0px 4px;
	background: #1d1b1b;
	display: inline-block;
	border-radius: 3px;
	width: 10px;
	height: 14px;
}
.slider-wrapper .owl-theme .owl-dots .owl-dot {
	display: block;
}
.slider-wrapper .owl-theme .owl-nav + .owl-dots {
	margin-top: 10px;
	position: absolute;
	top: 50%;
	right: 150px;
}

.delay {

	-webkit-animation-delay: .1s;
	-moz-animation-delay: .1s;
}
	.slider-text{
		h4{

			-webkit-animation-delay: .3s;
			-moz-animation-delay: .3s;
		}
		h1{

			-webkit-animation-delay: .5s;
			-moz-animation-delay: .5s;

		}
		p{

			-webkit-animation-delay: .7s;
			-moz-animation-delay: .7s;

		}
		a{

			-webkit-animation-delay: .8s;
			-moz-animation-delay: .8s;

		}
	}


/* ----------------------------------------------------------------
	Slider Style Two
-----------------------------------------------------------------*/

@media (min-width: 1200px) {
	.slider-2 {
		padding-top: 150px;
	}
}


.slider-text.style-two{
	text-align:center;
	h1{
		font-size:55px;
		@include WorkSans-bold;
		padding-bottom: 30px;
	}
	p{

	}
}
a.btn-two {
	font-size: 20px;
	color: #fff;
	background: #1d1b1b;
	padding: 13px 44px;
	border-radius: 0px;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
	margin-top: 10px;
	position: relative;
	text-transform: uppercase;
	&:hover:before{
		width:100%;
	}
	&:hover:after{
		width:100%;
	}
}
a.btn-two::before {
	position: absolute;
	content: '';
	width: 20px;
	height: 66px;
	top: -5px;
	left: -6px;
	border: 2px solid #1d1b1b;
	border-right: none;
	@include transition(all .3s ease-in);
}
a.btn-two::after {
	position: absolute;
	content: '';
	width: 20px;
	height: 66px;
	top: -5px;
	right: -6px;
	border: 2px solid #1d1b1b;
	border-left: none;
	@include transition(all .3s ease-in);
}

/* ----------------------------------------------------------------
	Slider Style Three
-----------------------------------------------------------------*/

.slider-3{
	padding-top:100px;
}

.slider-wrapper.style-three .owl-theme .owl-dots .owl-dot.active span {
	background: #d19e66;
	width: 18px;
	height: 10px;
}

.slider-wrapper.style-three .owl-theme .owl-dots .owl-dot span {
	width: 16px;
	margin: 0px 4px;
	background: #3f3f3f;
	display: inline-block;
	border-radius: 0px;
	width: 10px;
	height: 10px;
}
.slider-wrapper.style-three .owl-theme .owl-dots .owl-dot {
	display: inline-block;
}
.slider-wrapper.style-three .owl-theme .owl-nav + .owl-dots {
	margin-top: 10px;
	position: absolute;
	top: 92%;
	left: 0;
	width: 100%;
}