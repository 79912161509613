
@mixin font-face($font-family, $file-path, $font-weight, $font-style) {

	@font-face {
		font-family: $font-family;
		src: url('#{$file-path}.eot');
		src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
		url('#{$file-path}.woff') format('woff'),
		url('#{$file-path}.ttf') format('truetype'),
		url('#{$file-path}.svg##{$font-family}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}


/* Font */
@mixin WorkSans-semi-bold {
	font-family: 'Work Sans', sans-serif;
	font-weight: 600;
}

@mixin WorkSans-bold {
	font-family: 'Work Sans', sans-serif;
	font-weight: 700;
}

@mixin WorkSans-medium {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
}

@mixin WorkSans-normal {
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
}

@mixin WorkSans-light {
	font-family: 'Work Sans', sans-serif;
	font-weight: 300;
}


@mixin Roboto-bold {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
}

@mixin Roboto-normal {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
}

@mixin Roboto-light {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
}


@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin box-shadow($top, $left, $blur, $radius, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $radius $color;
		-moz-box-shadow:inset $top $left $blur $radius $color;
		box-shadow:inset $top $left $blur $radius $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $radius $color;
		-moz-box-shadow: $top $left $blur $radius $color;
		box-shadow: $top $left $blur $radius $color;
	}
}

@mixin box-shadow-2($top, $left, $blur , $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin text-field {
	display: inline-block;
	outline: none;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: .5em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	@include rounded();
	@include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
}

@mixin button($color: $red, $text_color: $white) {
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: .5em 2em .55em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	@include rounded();
	@include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
	color: $text_color !important;
	font-weight: bold;
	border: solid 1px darken($color, 18%);
	background: $color;
	@include gradient(saturate($color, 15%), darken($color, 15%));

	&:hover {
		text-decoration: none;
		background: saturate($color, 10%);
		@include gradient(saturate($color, 5%), darken($color, 5%));
	}

	&:active {
		position: relative;
		top: 1px;
		color: saturate($color, 15%);
		@include gradient(saturate($color, 15%), lighten($color, 15%));
	}
}

@mixin rounded($radius: 0.5em) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin gradient($from, $to) {
	background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background: -moz-linear-gradient(top,  $from, $to);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}



@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}


//usage: @include shift([property],[duration],[easing]);
@mixin shift($property: all, $duration: .3s, $ease: linear){
	-webkit-transition: $property $duration $ease;
	-moz-transition: $property $duration $ease;
	-o-transition: $property $duration $ease;
	transition: $property $duration $ease;
}


@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

@include keyframes(slide-down) {
	0% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}
}

.element {
	width: 100px;
	height: 100px;
	background: black;
	@include animation('slide-down 5s 3');
}


%visuallyhidden {
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

.visually-hidden {
	@extend %visuallyhidden;
}