

.logo {
	padding-top: 33px;
}
.mainmenu {
	> ul{
		padding:13px 0px;
		li{
			display:inline-block;

			a {
				@include WorkSans-semi-bold;
				color: $second_color;
				padding: 25px 30px;
				display: inline-block;
				font-size:15px;
				text-transform:uppercase;

				&:hover{
					color:$main_color;

				}

			}

			a.active{
				color:$main_color;
				&:before{
					color: $main_color;

				}
			}

		}
	}
	li.has-child > a{
		position:relative;

		&:before{
			position: absolute;
			content: '\f107';
			top: 27px;
			right: 10px;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			font-size: 12px;
			color: $second_color;

		}
		&:hover{
			&:before{

				color: $main_color;
				@include transition(all 0.15s ease-in-out);

			}

		}
	}
}

.menu-container{
	position:static;
}

.header-right-one {
	padding-top: 35px;
	float:right;
	ul {

		li {
			display: inline-block;
			text-align: center;
			position:relative;
			padding:0 13px;
			&:not(:last-child):after{
				content: '';
				position: absolute;
				width: 2px;
				background: #e8e6e6;
				height: 14px;
				top: 7px;
				right: 0;
			}
		}
		.top-search {border-right: none;position:relative;}
		a {
			color: $main_color;
			font-size: 15px;
			@include WorkSans-semi-bold;
			&:hover{
				color: $main_color;
			}

		}
	}
}
.custom-select {
	@include WorkSans-medium;
	height: auto;
	padding: 0;
	background: none;
	background-size: auto;
	border: none;
	text-align: left;
	background-image: url(../../media/images/icon/arrow.png);
	font-size: 14px;
	background-repeat: no-repeat;
	cursor:pointer;

}
.language .custom-select, .curency .custom-select{
	background-position: 100% 6px;
	padding-right: 17px;
}
.custom-select:focus {
	border-color: #fff;
	outline: 0;
	box-shadow: none;
}

.search-input {
	visibility: hidden;
	border: 1px solid #cfcccc;
	position: absolute;
	right: 0;
	padding: 7px 10px;
	top: 63px;
	width: 300px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	opacity: 0;
	border-radius: 3px;
	z-index: 8;
}
.search-input.active{
	visibility:visible;
	opacity:1;
}

.top-cart {
	position: relative;

}
.header-area .cart-area .cart-title a {
	font-size: 15px;
}
.top-bar .cart-area span {
	padding: 0 !important;
	margin-top: 0;
}
.cart-area > .btn {
	background: #444 none repeat scroll 0 0;
	border: medium none transparent;
	border-radius: 0;
	color: #fff;
	float: right;
	font-size: 13px;
	font-weight: normal;
	height: 56px;
	line-height: 18px;
	padding: 5px 10px 0;
	transition: all 0.3s ease 0s;
	width: 50%;
	z-index: 99;
	box-shadow:none;
}
.top-cart > .btn:hover {background: #e48080 none repeat scroll 0 0;}
#cart-total span {
	display: block;
	font-size: 20px;
	padding-bottom: 5px;
}
.cart-title a {color: $title_color;}
.cart-title a:hover {color: $main_color;}
.cart-drop {
	background: #fff none repeat scroll 0 0;
	border: 1px solid #ddd;
	opacity: 0;
	padding: 8px 9px 10px;
	position: absolute;
	right: 0;
	top: 90px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	visibility: hidden;
	width: 300px;
	z-index: 9999999;
	-webkit-box-shadow: 0px 20px 20px 0px rgba(80, 100, 126, 0.4);
	box-shadow: 0px 20px 20px 0px rgba(80, 100, 126, 0.4);
}
.top-cart .cart-drop.active {
	opacity: 1;
	top: 51px;
	visibility: visible;
}
.cart-img {
	display: inline-block;
	float: left;
	overflow: hidden;
}
.single-cart {
	display: block;
	margin-bottom: 10px;
	overflow: hidden;
	position: relative;
}
.cart-title > p a{
	color: #444;
	display: inline-block;
	font-size: 15px;
	letter-spacing: 1px;
	margin-bottom: 0;
	padding-left: 11px;
	padding-top: 7px;
	text-transform: capitalize;
}
.cart-price > p {
	display: inline-block;
	font-size: 15px;
	letter-spacing: 1px;
	margin-left: 11px;
	padding-top: 3px;
	color: #000;
}
.cart-price span {font-size: 20px;}
.cart-drop .fa-times {
	border-radius: 50px;
	color: #444;
	font-size: 14px;
	height: 16px;
	line-height: 15px;
	position: absolute;
	right: 2px;
	text-align: right;
	top: 38px;
	width: 16px;
}
.cart-sub-total > p {
	color: #444;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1px;
	padding: 2px 10px;
	text-align: left;
	text-transform: capitalize;
	border-bottom: 1px solid #f1f1f1;
	padding-bottom: 11px;
}
.cart-sub-total span {
	color: #6e6e6e;
	float: right;
	font-size: 18px;
}
.cart-sub-total p span {
	color: $main_color;
	font-size: 18px;
}
.cart-bottom div {padding: 5px 0;}
.cart-checkout a {
	padding: 5px 15px;
	background: #525353;
	color: #fff !important;
	border-radius: 0px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	margin-left: 5px;
	font-weight:700;
}
.cart-share a {
	padding: 5px 15px;
	background: #525353;
	color: #fff!important;
	border-radius: 0px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	margin-right: 5px;
	font-weight: 700;
}
.cart-checkout {
	display: inline-block;
	float: left;
	margin-top: 14px;
}
.cart-share {
	float: right;
	margin-top: 14px;
}
.cart-checkout i,.cart-share i{margin-right: 8px;}
.cart-share a:hover, .cart-checkout a:hover {
	background: $main_color;
	color: #fff;
}
.top-cart > a {
	color: #fff;
	font-size: 20px;
}
.cart-sub-total span {margin-top: 0px;}
.cart-checkout a i {font-size: 16px;}


#inline-content{display:inline}

.headroom--pinned {
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
	position: fixed;
	-webkit-animation-name: headerSlideDown;
	animation-name: headerSlideDown;
}
.header-area{
	background:#fff;
}
#header {
	z-index: 999;
	position:fixed;
	width: 100%;
	left: 0;
}

.headroom--pinned {
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
	position: fixed;
	-webkit-animation-name: headerSlideDown;
	animation-name: headerSlideDown;
}

.headroom {
	-webkit-transition: -webkit-transform 200ms linear;
	transition: -webkit-transform 200ms linear;
	-o-transition: transform 200ms linear;
	transition: transform 200ms linear;
	transition: transform 200ms linear, -webkit-transform 200ms linear;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
}

.headroom--unpinned {
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	position: fixed;
}

@-webkit-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}

.animated.slideUp {
	-webkit-animation-name: slideUp;
	animation-name: slideUp;
}
.hide-topbar .top-bar {
	display: none;
}

/* ==========================================================================
   Mobile Menu css
   ========================================================================== */
   .accordion-wrapper{display:none}

   .accordion .link {
   	cursor: pointer;
   	display: block;
   	padding: 15px 15px 15px 20px;
   	color:red;
   	font-size: 14px;
   	border-bottom: 1px solid #CCC;
   	position: relative;
   	-webkit-transition: all 0.4s ease;
   	-o-transition: all 0.4s ease;
   	transition: all 0.4s ease;
   	color:#3f3f3f;
   }
   .accordion  .link a:hover {
   	color: #0f0606;
   }

   .accordion li:last-child .link { border-bottom: 0; }

   .accordion li i {
   	position: absolute;
   	top: 16px;
   	left: 12px;
   	font-size: 18px;
   	color: #3f3f3f;
   	-webkit-transition: all 0.4s ease;
   	-o-transition: all 0.4s ease;
   	transition: all 0.4s ease;
   }
   .accordion li i.fa-chevron-down {
   	right: 12px;
   	left: auto;
   	font-size: 16px;
   }
   .accordion li.open .link {
   	color: #d19e66;
   }
   .accordion li.open i.fa-chevron-down {
   	-webkit-transform: rotate(180deg);
   	-ms-transform: rotate(180deg);
   	-o-transform: rotate(180deg);
   	transform: rotate(180deg);
   }
   .submenu {
   	display: none;
   	background: #fff;
   	font-size: 14px;
   }
   .submenu li {border-bottom: 1px solid #e0e1e0;}
   .submenu a {
   	display: block;
   	text-decoration: none;
   	color: #3f3f3f;
   	padding: 12px;
   	padding-left: 20px;
   	-webkit-transition: all 0.25s ease;
   	-o-transition: all 0.25s ease;
   	transition: all 0.25s ease;
   }
   .submenu a:hover {background: transparent;}
   .accordion {
   	position: fixed;
   	right: -110%;
   	top: 0px;
   	width: 300px;
   	z-index: 99999999999;
   	background: #fff;
   	-webkit-transition: all 0.25s ease;
   	-o-transition: all 0.25s ease;
   	transition: all 0.25s ease;
   	padding-top: 40px;
   	height: 100%;
   	overflow-y: scroll;
   }
   .accordion.active {
   	right: 0;
   	-webkit-transition: all 0.25s ease;
   	-o-transition: all 0.25s ease;
   	transition: all 0.25s ease;
   	position: fixed;
   	top: 0;
   }
   .mobile-open {
   	position: relative;
   	right: 0;
   	top: 0;
   	font-size: 27px;
   	color: $main_color;
   	float: left;
   	margin-top: 8px;
   }
   .mob-logo {
	width: 50%;
	padding-left: 17px;
	margin-bottom: 22px;
}
   .mobile-open:hover{color: $main_color;}
   .closeme {
   	position: absolute;
   	right: 11px;
   	top: 3px;
   	padding: 3px;
   }
   .closeme i {
   	position: relative !important;
   	left: 0 !important;
   	top: 0 !important;
   	font-size: 15px !important;
   }
   .accordion .top-contact-btn {
   	margin-left: 42px;
   	margin-top: 24px;
   	float: left;
   }
   .submenu li h4 {
   	color: #ffa725;
   	margin-left: 40px;
   	padding: 8px 0px;
   	font-size: 17px;
   }
   .accordion .out-link {
   	cursor: pointer;
   	display: block;
   	padding: 15px 15px 15px 20px;
   	color: #4D4D4D;
   	font-size: 14px;
   	border-bottom: 1px solid #CCC;
   	position: relative;
   	-webkit-transition: all 0.4s ease;
   	-o-transition: all 0.4s ease;
   	transition: all 0.4s ease;
   }
   .accordion .out-link a{
   	color:#3f3f3f;
   }
   #moble-search {
   	border: 1px solid #3f3f3f;
   	display: -ms-flexbox;
   	display: -webkit-box;
   	display: flex;
   	border-radius: 4px;
   	margin: 23px 17px 17px;
   }
   #moble-search input[type="text"] {
   	background: transparent;
   	height: 45px;
   	color: #3f3f3f;
   	border: 0;
   	padding: 0 10px;
   	width: 88%;
   	outline: 0;
   }
   #moble-search button[type="submit"] {
   	background: transparent;
   	border: 0;
   	color: #3f3f3f;
   	cursor: pointer;
   }
   .mobile-header{display:none}




/* ==========================================================================
    Dropdown menu css
   ========================================================================== */

   .sub-menu {
   	position: absolute;
   	z-index: 99999;
   	background-color: #fff;
   	width: 300px;
   	@include box-shadow(1.236px, 3.804px, 8px, 0px,rgba(102, 101, 101, 0.48));
   	top: 115%;
   	visibility: hidden;
   	opacity: 0;
   	-webkit-transition: all 0.3s ease 0s;
   	-o-transition: all 0.3s ease 0s;
   	transition: all 0.3s ease 0s;
   }
   .sub-menu li{position: relative}
   .mainmenu .sub-menu a:hover {
   	padding-right: 0;
   	-webkit-transition: all 0.3s ease 0s;
   	-o-transition: all 0.3s ease 0s;
   	transition: all 0.3s ease 0s;
   	background: #f6f6f6;
   }
   .mainmenu ul li .sub-menu li a {
   	padding: 15px 25px;
   	width: 100%;
   	display: block;
   	color: #3f3f3f;
   	font-size: 16px;
   	text-transform: capitalize;
   	font-weight: 400;
   	@include WorkSans-normal;
   }
   .mainmenu li:hover .sub-menu {
   	visibility: visible;
   	top: 84%;
   	opacity: 1;
   	-webkit-transition: all 0.3s ease 0s;
   	-o-transition: all 0.3s ease 0s;
   	transition: all 0.3s ease 0s;
   }
   .mainmenu ul li .sub-menu li {
   	width: 100%;
   }



/* ==========================================================================
    Mega menu area css
   ========================================================================== */


   .mega-catagory {
   	float: left;
   }
   .menu-rel-container{
   	position:relative;
   }
   .four-col .mega-catagory {width:25%}
   .five-col .mega-product {
   	width: 20%;
   	float: left;
   	padding: 0 15px;
   }
   .mega-menu {
   	position: absolute;
   	top: 103px;
   	left: 0;
   	background: #fff;
   	z-index: 999999;
   	width: 100%;
   	@include box-shadow(1.236px, 3.804px, 8px, 0px, rgba(102, 101, 101, 0.09));
   	padding: 12px 30px 20px;
   	padding-left: 31px;
   	opacity: 0;
   	visibility: hidden;
   }
   .mega-menu.five-col {
   	padding: 30px 30px 30px;
   }

   .mega-menu .sin-product{
   	margin-bottom: 0;
   }

   .mega-catagory.per-20{
   	width:20%;
   }
   .mega-catagory.per-30{
   	width:30%;
   }

   .mega-menu .sin-product h5.pro-title a {
   	padding: 0;
   	font-size:15px;
   }
   .mega-menu .sin-product span {
		font-size: 15px;
	}
   .mainmenu ul li .mega-product h4 a {
   	padding: 16px 0px;
   	border-bottom: 1px solid #e3dada;
   	margin-right: 35px;
   	color: #383737;
   	text-transform: capitalize;
   	font-family: 'Work Sans', sans-serif;
   	font-weight: 400;
   	font-size: 18px;
   }

   .mainmenu li:hover .mega-menu {
   	visibility: visible;
   	top: 84%;
   	opacity: 1;
   	-webkit-transition: all 0.3s ease 0s;
   	transition: all 0.3s ease 0s;
   	border-top: 1px solid #f5f5f5;
   }
   .mainmenu .mega-button a {
   	color: #383737;
   	font-size: 15px;
   	padding: 7px 20px;
   	width: 100%;
   	text-transform: uppercase;
   	font-weight: 400!important;
   	position:relative;
   	@include WorkSans-normal;
   	padding-left: 0;
   }

/* -ms-transform: translate(0px,-10px);
-webkit-transform: translate(0px,-10px);
transform: translate(0px,-10px); */
.mainmenu ul li .mega-catagory h4 a {
	padding: 16px 0px;
	border-bottom: 1px solid #e3dada;
	margin-right: 35px;
	color: #383737;
	text-transform: capitalize;
	font-weight: 400;
	font-size: 18px;

}
.mega-button {padding-top: 10px;}
.mainmenu .mega-button a:hover {color: $main_color;}
.mega-img.mega-catagory a {
	padding: 7px 13px !important;
	display: inline !important;
	border-radius: 6px;
	color: #fff;
}

.mega-catagory.mega-img {
	text-align: center;
	padding: 6px;
	padding-top: 12px;
}
.mega-catagory.mega-img img{
	@include transition(all .3s);
}
.mega-catagory.mega-img:hover img{
	-ms-transform: translate(0px,-5px);
	-webkit-transform: translate(0px,-5px);
	transform: translate(0px,-5px);
	@include transition(all .3s);
}
.mega-button li {
	display: block;
	width: 100%;
}

.mega-product .sin-product:hover .pro-img::after {
	opacity: .2;
}

.mobile-login{
	text-align: center;
	margin-top: 25px;
	color: #3f3f3f;
	font-size: 12px;
	a{

		padding: 15px 15px 15px 20px;
		font-size: 16px;
		color: #3f3f3f;

	}
}

.mainmenu ul li .mega-product h4 a {
	padding: 0px 0px 16px;
}