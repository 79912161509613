
	.feature-area{
		background: #fff url("../../media/images/bg/1.jpg") no-repeat center ;
		background-size:cover;
		padding:105px 0;

	}

	.sin-feature {
		@include WorkSans-medium;
		padding:15px;
		position: relative;
		height: 185px;
		background:#fff;
		margin:15px 0;
		.icon{
			color: #000;
			width: 20%;
			float:left;
			i{
				font-size:38px;
			}
		}
		.f-content {
			width: 80%;
			float: right;
			text-align: left;
			padding-top: 5px;
			h6 {
				font-size: 18px;
				margin-bottom: 0;
				margin-top: 4px;
				a{
					color:$main_color;
					&:hover{
						color:$second_color;
					}
				}
			}
			p {
				font-size:15px;
				color: #7b7b7b;
			}
		 }

		 .inner-sin-feature{
		 	-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			top: 50%;
			position: relative;
			padding-left: 53px;
			position:relative;
			z-index:999;
			overflow: hidden;

		 }
		 &:after{
		 	position:absolute;
		 	top:10px;
		 	left:10px;
		 	right:10px;
		 	bottom: 10px;
		 	content:'';
		 	border:1px dashed #252525;
		 }

	}
